import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from '../../services/shared/translators.service';
import { MatDialog } from '@angular/material/dialog';
import { OpenPageService } from '../../services/shared/open-page.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  idioma: Subscription;

  constructor(
      private translatorService: Translators,
      public dialog: MatDialog,
      private openPageService: OpenPageService,

  ) {
    // this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
    //     this.idioma = resp;
    // });
  }

  ngOnInit(): void {
  }

  openBlank(type: string) {
    this.openPageService.openBlank(type);
  }

}
