import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit{
    location: any;
    routerSubscription: any;

    constructor(private router: Router) {
    }

    ngOnInit(){
        // Llama a clearCacheAndReload solo si no ha sido ejecutada previamente
        if (!localStorage.getItem('cacheCleared')) {
            this.clearCacheAndReload();
        }
        this.recallJsFuntions();
    }

    private clearCacheAndReload() {
        if ('caches' in window) {
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
        }
        
        // Marca en localStorage que la caché ha sido limpiada
        localStorage.setItem('cacheCleared', 'true');
        
        location.reload(); // Recarga la página
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

}
