<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Quienes Somos</li>
            </ul>
            <h2>Quienes Somos</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" alt="image" width="10%"></div>
</div>

<div class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center mt-5">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Quienes Somos</span>
                    <!-- <h2>Proceso</h2> -->
                    <!--<p><strong>Somos un nexo entre las empresas extranjeras que contratan empleados temporarios y la mano de obra calificada que estos requieren. Trabajamos sobre una programa “llave en mano” que contempla:  Scouting / Capacitacion / Contratacion / Seguimiento/ Continuidad.</strong></p>-->
                    <!-- le cambie el translate a estos tambien por las dudas -->
                    <p style="text-align: justify;">
                        <strong>
                            Los beneficiarios de nuestra Obra Social, Signo Médico, cuentan con una amplia y libre elección de Profesionales e
                            Instituciones Sanatoriales prestigiosas en todo el País. Para saber más sobre el detalle de toda la cobertura y
                            las
                            normas de utilización de los servicios, le sugerimos ver la Norma de Cobertura correspondiente a su plan en
                            <a href="http://www.osoetsylra.org" target="_blank">www.osoetsylra.org</a>
                        </strong>
                    </p>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="assets/img/quienes-somos/signomedico.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
