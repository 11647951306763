<app-loading *ngIf="!flagLoaded" class="m-5">
</app-loading>
<div *ngIf="flagLoaded">

    <div class="row" *ngIf='this.imgs.length > 0'>
        <div class="col-lg-18 col-md-18">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="fotos">
                        <mat-panel-title>
                            Documentación Solicitud - Fotos
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-ofertas-gallery [imagenes]="this.imgs" [id_usuario]="this.usuario._id"></app-ofertas-gallery>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row" *ngIf='!this.imgs.length > 0'>
        <div class="col-lg-18 col-md-18">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Sin Documentación Solicitud - Fotos
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <div class="col-sm-12">
                            <mat-list>
                                <mat-list-item>
                                    <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <br>
    <br>
    <div class="row" *ngIf='this.files.length > 0'>
        <div class="col-lg-18 col-md-18">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="archivos">
                        <mat-panel-title>
                            Documentación Solicitud - Archivos
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-ofertas-files [files]="this.files" [usuario]="this.usuario"></app-ofertas-files>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="row" *ngIf='!this.files.length > 0'>
        <div class="col-lg-18 col-md-18">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Sin Documentación Solicitud - Archivos
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <div class="col-sm-12">
                            <mat-list>
                                <mat-list-item>
                                    <i class="fa fa-file-archive-o fa-2x" aria-hidden="true"></i>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>