<mat-vertical-stepper  *ngIf="vertical" #stepper>
    <mat-step [editable]="false" [interacted]="false">
        <ng-template matStepLabel>Presentado</ng-template>
        <p>Solicitud de aprobación para el trámite seleccionado</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>Documentación a presentar</ng-template>
        <p>Cargue su documentación necesaria...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>En Revisión</ng-template>
        <p>Su documentación ha sido presentada con éxito y se encuentra en estado de REVISIÓN. Una vez que su documentación haya sido aprobada, recibirá un correo electrónico de confirmación o nos pondremos en contacto con usted para proporcionarle más detalles. ¡Gracias por su colaboración!</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <ng-template matStepLabel>En Auditoría Médica</ng-template>
        <p>La documentación esta en auditoría médica...</p>
    </mat-step>
    <mat-step color="accent" [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>Aprobado</ng-template>
        <p>Felicitaciones!, su trámite fue aprobado</p>
    </mat-step>
    <mat-step *ngIf="stepperSet === 5" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Rechazado</ng-template>
        <p>Su trámite ha sido RECHAZADO</p>
    </mat-step>

    <!--
    <mat-step [editable]="false" [completed]='step4Completed'>
        <ng-template matStepLabel>Pago</ng-template>
        <p>Realice el pago para continuar con su trámite</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>Datos Personales</ng-template>
        <p>Cargue sus datos personales</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>Documentación Parcial</ng-template>
        <p>Documentos Envio parcial</p>
    </mat-step> -->
    <!-- <mat-step color="accent" [editable]="false" [completed]='step7Completed'>
        <ng-template matStepLabel>Comprobando Pago</ng-template>
        <p>Comprobando Pago</p>
    </mat-step> -->
    <!-- PONER EL step DE ANULADA 9
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>-->
</mat-vertical-stepper>

<mat-horizontal-stepper *ngIf="!vertical" #stepper>
    <mat-step [editable]="false">
        <p>Solicitud de aprobación para el trámite seleccionado</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <p>Cargue su documentación necesaria...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <p>Su documentación ha sido presentada con éxito y se encuentra en estado de REVISIÓN. Una vez que su documentación haya sido aprobada, recibirá un correo electrónico de confirmación o nos pondremos en contacto con usted para proporcionarle más detalles. ¡Gracias por su colaboración!</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step3Completed'>
        <p>La documentación esta en auditoría médica...</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step4Completed' >
        <p>Felicitaciones!, su trámite fue aprobado</p>
    </mat-step>
    <mat-step *ngIf="stepperSet === 5" [errorMessage]="true" [completed]="true">
        <p>Su trámite ha sido RECHAZADO</p>
    </mat-step>

     <!-- PONER EL step DE ANULADA 9
    <mat-step *ngIf="stepperSet === 9" [errorMessage]="true" [completed]="true">
        <ng-template color="warn" matStepLabel>Solicitud Anulada</ng-template>
        <p>Su Solicitud fue anulada</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step1Completed'>
        <ng-template matStepLabel>Datos Personales</ng-template> 
        <p>Cargue sus datos personales</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step2Completed'>
        <ng-template matStepLabel>Documentación Parcial</ng-template>
        <p>Documentos Envio parcial</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step5Completed'>
        <ng-template matStepLabel>Pre-Aprobado</ng-template> 
        <p>Pre-Aprobado: ¡Felicitaciones! En breve recibirás una solicitud de pago</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step6Completed'>
         <ng-template matStepLabel>Pago</ng-template> 
        <p>Realice el pago para continuar con su trámite</p>
    </mat-step>
    <mat-step [editable]="false" [completed]='step7Completed'>
         <ng-template matStepLabel>Comprobando Pago</ng-template> 
        <p>Comprobando Pago</p>
    </mat-step> -->
</mat-horizontal-stepper>

