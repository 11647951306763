<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li *ngFor="let route of routes">
          <a routerLink="{{route.url}}">{{route.label}}</a>
        </li>
      </ul>
      <h2>Alta Grupo Familiar</h2>
      <h4 *ngIf="usuario">Alta de miembro familiar asociado al afiliado:
        {{usuario?.nombrePersona.toUpperCase()}} {{usuario?.apellidoPersona.toUpperCase()}}</h4>
    </div>
  </div>
</div>

<div class="profile-authentication-area ptb-70 mt-5 ">

  <div class="container">
    <div class="row justify-center">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="register-form animate__animated animate__fadeIn">

          <h2>Registro Familiar</h2>

          <div class="container">
            <div class="info">
              Por favor, ingrese el DNI del familiar que desea dar de alta. En caso
              de no encontrarlo, le solicitamos ponerse en contacto con la administración. Muchas Gracias.
            </div>
          </div>
        

          <div class="container">
            <div class="info alert alert-info">
                <strong>ATENCIÓN:</strong> Si desea crear una cuenta para que su familiar pueda realizar trámites en su cuenta personal, seleccione la siguiente opción. Su familiar recibirá un código de activación en su correo electrónico. Utilice este código para activar la cuenta y comenzar a realizar trámites.
                <div class="form-check form-switch mt-3">
                    <input class="form-check-input" type="checkbox" id="toggleEmailRegistration" [(ngModel)]="flagDataGecrosEmail">
                    <label class="form-check-label" for="toggleEmailRegistration">Activar registro con correo electrónico</label>
                </div>
                <!-- <p class="mt-3"></p> -->
            </div>
          </div>
        

          <form [formGroup]="registerForm"
                (ngSubmit)="registerUser()">
            <div class="box-input-shadow-dni">
              <div class="form-group inner-addon right-addon ">
                <label>Número de Identificación - D.N.I </label>
                <!-- <div class="input-group">                
                  <input type="text"
                         formControlName="identificacion"
                         class="form-control"
                         (input)="searchAfiliado()"
                         [(ngModel)]="identificacion"
                         [placeholder]='"Escriba su número de identificación"'
                         [class.is-invalid]="identificacionNoValida">
                  <div *ngIf="(!flagDataGecros && viewSpinner)"
                       class="loader-container">
                    <strong>Buscando Afiliado</strong>
                    <i class="loader"></i>
                  </div>
                </div> -->
                <div class="input-group">                
                  <input type="text"
                         formControlName="identificacion"
                         class="form-control"
                         (input)="searchAfiliado()"
                         [(ngModel)]="identificacion"
                         [placeholder]='"Escriba su número de identificación"'
                         [class.is-invalid]="identificacionNoValida">
                  <div *ngIf="(!flagDataGecros && viewSpinner)"
                       class="loader-container">
                    <strong>Buscando Afiliado</strong>
                    <i class="loader"></i>
                  </div>
                </div>
                <mat-error *ngIf="identificacionNoValida"
                           style="color:#580f2e">Ingrese Número de Identificación - D.N.I</mat-error>
                <br>
                <div *ngIf="showTimeoutMessage && (!flagDataGecros && !dataGecros)"
                     class="text-danger">No se han
                  encontrado sus datos de afiliado, para
                  registrarse primero debe afiliarse a <strong><a href="https://gecros.com/"
                       target="_blank"
                       rel="noreferrer">Gecros</a></strong>.</div>
              </div>

            </div>


            <!-- REGISTRO -->
            <div *ngIf="flagDataGecros">
              <div class="form-group">
                <div class="row mb-4">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-outline">
                      <label>Nombre</label>
                      <input type="text" 
                            disabled
                            formControlName="nombrePersona"
                            [placeholder]='"Escriba su nombre completo"'
                            [class.is-invalid]="nombreNoValido"
                            class="form-control"
                            style="margin-right: 3px;">
                      <small style="margin-right: 30px;"
                            *ngIf="nombreNoValido"
                            class="text-danger">Escriba su nombre completo</small>
                    </div>
                  </div>

                  <div class="col-lg-6 col-xs-12">
                    <div class="form-outline">
                      <label>Apellido</label>
                      <input 
                            disabled
                            type="text"
                            formControlName="apellidoPersona"
                            [placeholder]='"Escriba su apellido completo"'
                            [class.is-invalid]="apellidoNoValido"
                            class="form-control">
                      <small *ngIf="apellidoNoValido"
                            class="text-danger">Escriba su apellido completo</small>
                    </div>
                  </div>

                  <div class="col-lg-6 col-xs-12">
                    <div class="form-outline">
                      <label class="form-label">Parentesco</label>
                      <mat-form-field appearance="fill"
                                      [style.width.%]=100>
                        <mat-label>Seleccione el parentesco</mat-label>
                        <mat-select formControlName="parentesco">
                          <mat-option *ngFor="let parentesco of parentescos"
                                      [value]="parentesco">{{ parentesco }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="parentescoNoSeleccionado">Seleccione el parentesco</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- REGISTRO CON EMAIL -->
            <div *ngIf="flagDataGecrosEmail && flagDataGecros">
              <div class="form-group">
                <label>Correo Electrónico</label>
                <input formControlName="email"
                      type="email"
                      class="form-control text-lowercase"
                      [placeholder]='"Ingrese su correo electrónico"'
                      [class.is-invalid]="emailNoValido">
                <small *ngIf="emailNoValido"
                      class="text-danger">Ingrese un correo electrónico válido</small>
              </div>

              <div class="form-group">
                <label>Confirme correo electrónico</label>
                <input formControlName="confirmEmail"
                      type="email"
                      class="form-control text-lowercase"
                      [placeholder]='"Confirme correo electrónico"'
                      [class.is-invalid]="confirmEmailNoValido">
                <small *ngIf="confirmEmailNoValido"
                      class="text-danger">Los correos electrónicos no coinciden</small>
              </div>

              <div class="form-group inner-addon right-addon">
                <label>Contraseña</label>
                <div class="input-group">
                  <input formControlName="password"
                        [type]="showPasswordRegister ? 'text' : 'password'"
                        class="form-control"
                        [placeholder]='"Ingrese su contraseña"'
                        [class.is-invalid]="passwordNoValido"
                        (cut)="$event.preventDefault()"
                        (copy)="$event.preventDefault()"
                        (paste)="$event.preventDefault()">
                  <i alt="show"
                    class="far fa-eye eye-show"
                    (click)="showPasswordRegister = !showPasswordRegister"
                    [class.hide]="showPasswordRegister"></i>
                  <i alt="hide"
                    class="far fa-eye-slash eye-hide"
                    (click)="showPasswordRegister = !showPasswordRegister"
                    [class.hide]="!showPasswordRegister"></i><br>
                </div>
                <small *ngIf="passwordNoValido"
                      class="text-danger">La contraseña debe ser de 8 caracteres.</small>
              </div>

              <div class="form-group inner-addon right-addon">
                <label>Confirme contraseña</label>
                <div class="input-group">
                  <input formControlName="confirmPassword"
                        [type]="showPasswordConfirm ? 'text' : 'password'"
                        class="form-control"
                        [placeholder]='"Confirme contraseña"'
                        [class.is-invalid]="confirmPasswordNoValido">
                  <i alt="show"
                    class="far fa-eye eye-show"
                    (click)="showPasswordConfirm = !showPasswordConfirm"
                    [class.hide]="showPasswordConfirm"></i>
                  <i alt="hide"
                    class="far fa-eye-slash eye-hide"
                    (click)="showPasswordConfirm = !showPasswordConfirm"
                    [class.hide]="!showPasswordConfirm"></i><br>
                </div>
                <small *ngIf="confirmPasswordNoValido"
                      class="text-danger">Las contraseñas no coinciden</small>
              </div>

              <p class="description">La contraseña debe ser de 8 caracteres.</p>                         

              <br>

              <div class="form-group">
                <input type="checkbox"
                      formControlName="proteccionDeDatos"
                      style="margin-right: 5px !important;"> <em>He leído y acepto la cláusula de Información sobre Protección de Datos
                  - <a (click)="openBlank('CFR')"
                    class="link"
                    style="text-decoration: none;">Cláusula Registro</a></em>
              </div>

              <div class="form-group">
                <input type="checkbox"
                      formControlName="clausula"
                      style="margin-right: 5px !important;">
                <em>Autorizo al tratamiento de mis datos para poder recibir información por medios electrónicos</em>
              </div>

              <div class="form-group">
                <input type="checkbox"
                      formControlName="aceptaTerminos"
                      style="margin-right: 5px !important;">
                <em>He leído y acepto los Términos y Condiciones
                  - <a (click)="openBlank('TC')"
                    class="link"
                    style="text-decoration: none;">Términos y condiciones</a></em>
              </div>
            </div>

            <button [disabled]="!registerForm.valid || !dataGecros"
                    [ngClass]="{'custom-disabled': !registerForm.valid  || !dataGecros}"> Registrar Familiar </button>
            <br>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>