<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/ofertas-administracion-listado">Solicitudes</a></li>
                <li *ngIf="flagLoaded"> Solicitud: {{ this.solicitud._id }}</li>
            </ul>
            <h2 *ngIf="this.flagLoaded">Detalle Solicitud - {{this.solicitud.lastState.numberState |
                estadosPipe}}</h2>
            <div *ngIf="this.flagLabelAprobado" class="p-3 mb-2 bg-success text-white">APROBADA
            </div>

            <div *ngIf="this.flagLabelRechazado" class="p-3 mb-2 bg-danger text-white">
                RECHAZADA
                <div *ngIf="this.solicitud.lastState.observaciones" class="alert alert-info mt-2" role="alert">
                    <strong>Motivo:</strong> {{ this.solicitud.lastState?.observaciones }}
                </div>
            </div>

        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>
<br>
<br>
<div *ngIf="flagLoaded">
    <div class="container">
        <div class="row align-items-center">
            <!-- estados administrativos-->
            <span *ngIf="flagEnRevision" class="alert alert-primary" role="alert">Solicitud actualmente en
                revisión por parte de la sede.</span>
            <span *ngIf="flagEnAuditoria" class="alert alert-info" role="alert">Solicitud en Auditoria
                médica</span>
            <span *ngIf="flagLabelAprobado" class="alert alert-success" role="alert">Solicitud
                Aprobada</span>
            <span *ngIf="flagLabelRechazado" class="alert alert-danger" role="alert">Solicitud
                Rechazada</span>

            <app-ofertas-estado-detalle [solicitud]="solicitud" [usuario]="usuario" [beneficiario]="beneficiario"
                [nombrePais]="nombrePais"
                [estadoDocumentacion]="estadoDocumentacion"></app-ofertas-estado-detalle>
            <br>
            <mat-divider></mat-divider>
            <br>
            <div class="col-lg-8">
                <!-- stepper -->
                <app-stepper [stepperSet]="this.solicitud.lastState.numberState"
                    [vertical]="false"></app-stepper>
            </div>
            <br>
            <div class="courses-details-area pb-100">
                <div class="container">
                    <div class="row" *ngIf='this.imgs.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="fotos">
                                        <mat-panel-title>
                                            Documentación Solicitud - Fotos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-ofertas-gallery [imagenes]="this.imgs"
                                        [id_usuario]="this.usuario._id"></app-ofertas-gallery>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row" *ngIf='!this.imgs.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            SIN Documentación Solicitud - Fotos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <mat-list>
                                                <mat-list-item>
                                                    <i class="fa fa-file-archive-o fa-2x"
                                                        aria-hidden="true"></i>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row" *ngIf='this.files.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="archivos">
                                        <mat-panel-title>
                                            Documentación Trámite - Archivos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-ofertas-files [files]="this.files"
                                        [usuario]="this.usuario"></app-ofertas-files>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row" *ngIf='!this.files.length > 0'>
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            SIN Documentación Solicitud - Archivos
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <mat-list>
                                                <mat-list-item>
                                                    <i class="fa fa-file-archive-o fa-2x"
                                                        aria-hidden="true"></i>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <!-- SPIINER -->
                    <!-- <div *ngIf="flagSpinner">
                        <app-spinner-colors></app-spinner-colors>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="clasico">
                                        <mat-panel-title>
                                            Tratar Solicitud
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <br>

                                    <!-- REVISION -->

                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>Revisión</mat-card-title>
                                            <br>
                                            <mat-card-subtitle>
                                                Si las autorizaciones y/o documentación presentadas por
                                                el afiliado están incompletas o falta documentación
                                                necesaria para poder
                                                autorizarlas, envíe una observación para que el afiliado
                                                pueda completar
                                                su solicitud adjuntando la documentación faltante. De esta
                                                manera, se
                                                podrá continuar con el proceso de solicitud.
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <mat-slide-toggle *ngIf="activarEnRevision"
                                                [(ngModel)]="isCheckedEnRevision">
                                                Active la opción enviar una
                                                observación al afiliado con
                                                respecto a su documentación.
                                            </mat-slide-toggle>
                                            <section *ngIf="isCheckedEnRevision">
                                                <br>
                                                <mat-form-field class="motivo-text-area"
                                                    mat-focus-on-activation>
                                                    <mat-label>Observaciones</mat-label>
                                                    <textarea matInput
                                                        placeholder="Incluya las observaciones pertinentes para informar al afiliado"
                                                        mat-autosize="true" [(ngModel)]="motivoRevision"
                                                        (input)="habilitarBotonObservaciones(motivoRevision)"></textarea>
                                                </mat-form-field>
                                                <br>
                                                <button *ngIf="activarEnRevision"
                                                    (click)="actualizarEstadoSolicitud(this.solicitud._id, this.iAppOfertas.estado_2.number,false,  this.iAppOfertas.estado_2.name, true, motivoRevision)"
                                                    mat-raised-button color="success"
                                                    [disabled]="!habilitarEnviarObservacion">Enviar
                                                    Observación</button>
                                                <button *ngIf=!activarEnRevision mat-raised-button
                                                    color="accent" disabled>Enviar Observación</button>
                                            </section>
                                        </mat-card-actions>
                                    </mat-card>
                                    <br>

                                    <!-- DERIVACION -->

                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>Derivación a Auditoria Médica</mat-card-title>
                                            <br>
                                            <mat-card-subtitle>
                                                Si la autorización presenta dificultades
                                                y no se puede aprobar, es recomendable
                                                derivarla a auditoría para que realice
                                                un análisis exhaustivo.
                                            </mat-card-subtitle>
                                            <mat-card-subtitle class="infoDerivacion">
                                                <span class="importanteDerivacion">Importante: </span> 
                                                <span>cuando derive la solicitud a Auditoria Médica, también enviará los documentos adjuntos de la solicitud por e-mail al sector de Auditoría.</span>
                                            </mat-card-subtitle>                                              
                                        </mat-card-header>
                                        <mat-card-actions>

                                        <div>

                                            <!-- Campo para la observación -->
                                            <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 15px;">
                                                <mat-label>Observación email (opcional)</mat-label>
                                                <textarea matInput [(ngModel)]="observacionEmailAuditoriaMedica" placeholder="Ingrese su observación aquí..."></textarea>
                                            </mat-form-field>
                                         
                                            <button *ngIf="activarEnAuditoria && botonAuditoriaHabilitado"
                                            (click)="botonDerivarAuditoriaMedica(this.solicitud._id)" mat-raised-button
                                            color="primary" class="botonDerivar" >Derivar a Auditoría Médica</button>                                               

                                            <button *ngIf="!activarEnAuditoria || !botonAuditoriaHabilitado"
                                            mat-raised-button color="accent"
                                            [disabled]="botonAuditoriaHabilitado"
                                            class="disabled-button botonDerivar" disabled>Derivar a Auditoría
                                            Médica</button>

                                        </div>

                                        <!-- NUEVA FUNCIÓN ENVÍA DOCUMENTOS SI ES NECESARIO -->                                    
                                          
                                        <div *ngIf="this.solicitud?.adjuntos.length > 0" class="email-button-container">                                                               
                                        
                                            <!-- Botón de enviar documentos -->
                                            <button (click)="enviarDocumentosSolicitud(this.solicitud._id)" mat-button color="accent" aria-label="Enviar Documentos a Auditoría Médica" class="email-button" style="margin-bottom: 10px;">
                                                <mat-icon>email</mat-icon>
                                                <mat-icon>attach_file</mat-icon>
                                            </button>

                                            <!-- Mensaje informativo -->
                                            <div class="alert alert-info" role="alert">
                                                <strong>Importante:</strong> Al enviar los documentos adjuntos a Auditoría Médica, no se cambiará el estado de la solicitud.
                                            </div>  
                                        </div>
                                        
                                        <div *ngIf="this.solicitud?.adjuntos.length <= 0" class="email-button-container">
                                            <div class="alert alert-info" role="alert">
                                                <strong>Importante:</strong> No hay documentos adjuntos para enviar.
                                            </div>     
                                            <button mat-button disabled aria-label="Enviar Documentos a Auditoría Médica" class="email-button disabled-button">
                                                <mat-icon>email</mat-icon>
                                                <mat-icon>attach_file</mat-icon>
                                            </button>
                                        </div>
                                     
                                            <!-- FIN NUEVA FUNCION ENVIA DOCUMENTOS SI ES NECESARIO -->

                                        </mat-card-actions>
                                        <div *ngIf="mostrarLeyenda"
                                            class="alert alert-primary d-flex align-items-center"
                                            role="alert">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                fill="currentColor"
                                                class="bi bi-info-fill flex-shrink-0 me-2"
                                                viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                <path
                                                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                            <div>
                                                La solicitud con el número:
                                                <strong>{{this.solicitud?._nro}}</strong> perteneciente al
                                                afiliado
                                                <strong>{{this.solicitud.usuario?.nombrePersona.toUpperCase()}}
                                                    {{this.solicitud.usuario?.apellidoPersona.toUpperCase()}}</strong>
                                                ha sido derivada a <strong>Auditoría Médica</strong>.
                                            </div>
                                        </div>
                                    </mat-card>
                                    <br>

                                    <!-- APROBACION -->

                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>Aprobar</mat-card-title>
                                            <mat-card-subtitle>
                                                <br>
                                                Las autorizaciones y documentación
                                                presentadas están correctos.
                                                <br>
                                                <br>
                                                Recuerde que al APROBAR, al afiliado se le notificará que su
                                                autorización fue aprobada
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <br>
                                            <mat-slide-toggle *ngIf="activarAprobar"
                                                [(ngModel)]="isCheckedAprobar">Active la opción para
                                                aprobar</mat-slide-toggle>
                                            <br>
                                            <section *ngIf="isCheckedAprobar" class="section">
                                                <br>
                                                <p>
                                                    Por favor, adjunte el archivo PDF necesario para la
                                                    aprobación de la solicitud.
                                                </p>
                                                <br>
                                                <div class="file-upload" [class.active]="isActive">
                                                    <div class="file-select">
                                                        <div class="file-select-button" id="fileName"
                                                            (click)="fileInput.click()">Seleccione un
                                                            archivo PDF</div>
                                                        <div class="file-select-name" id="noFile">{{
                                                            selectedFileName || 'No se selecciono ningún
                                                            archivo...' }}</div>
                                                        <input type="file" name="chooseFile" id="chooseFile"
                                                            #fileInput accept="application/pdf"
                                                            (change)="onFileSelected($event)">
                                                    </div>
                                                </div>
                                                <br>
                                                <button (click)="aprobarSolicitud()" mat-raised-button
                                                    color="success" [disabled]="flagDisableButtonApprove"
                                                    [ngClass]="{'custom-disabled': flagDisableButtonApprove}">Aprobar
                                                    Trámite</button>
                                            </section>


                                        </mat-card-actions>
                                    </mat-card>
                                    <br>

                                    <!-- RECHAZO -->

                                    <mat-card>
                                        <mat-card-header>
                                            <mat-card-title>Rechazar</mat-card-title>
                                            <br>
                                            <mat-card-subtitle>
                                                <div class="alert alert-danger" role="alert">
                                                    <h5 class="alert-heading">¡Importante!</h5>
                                                    <p>
                                                        Al <strong>RECHAZAR</strong> una solicitud, el
                                                        afiliado
                                                        tendrá que reenviar la autorización y
                                                        su documentación nuevamente.
                                                    </p>
                                                </div>
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-actions>
                                            <mat-slide-toggle *ngIf="activarRechazar"
                                                [(ngModel)]="isCheckedRechazar">Active la opción para
                                                rechazar</mat-slide-toggle>
                                            <section *ngIf="isCheckedRechazar">
                                                <br>
                                                <mat-form-field class="motivo-text-area"
                                                    mat-focus-on-activation>
                                                    <mat-label>Motivo de Rechazo</mat-label>
                                                    <textarea matInput
                                                        placeholder="Escriba el motivo por el cual se rechaza la solicitud..."
                                                        mat-autosize="true" [(ngModel)]="motivoRechazo"
                                                        (input)="habilitarBotonObservaciones(motivoRechazo)"></textarea>
                                                </mat-form-field>
                                                <br>
                                                <button *ngIf="activarRechazar"
                                                    (click)="actualizarEstadoSolicitud(this.solicitud._id,this.iAppOfertas.estado_5.number,false, this.iAppOfertas.estado_5.name, true, motivoRechazo)"
                                                    mat-raised-button color="accent"
                                                    [disabled]="!habilitarEnviarObservacion">Rechazar</button>
                                                <button *ngIf=!activarRechazar mat-raised-button
                                                    color="warn" disabled>Rechazar</button>
                                            </section>
                                        </mat-card-actions>
                                    </mat-card>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-18 col-md-18">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="clasico">
                                        <mat-panel-title>
                                            Descripción
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <app-detalle [actividad]=actividad
                                        [nombrePais]=nombrePais></app-detalle>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button *ngIf=activarCerrarModal (click)="closeModal()" class="btn btn-secondary"
                            type="button">SALIR</button>
                        <button *ngIf=!activarCerrarModal (click)="closeModal()" class="btn btn-secondary"
                            type="button" disabled>SALIR</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>