import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { GuiMsjService } from '../shared/gui-msj.service';
import { GuiToolsService } from '../shared/gui-tools.service';
import { Response } from '../../interfaces/interface-bakend/response';
import { Register } from '../../interfaces/interface-frontend/register';
import Swal from 'sweetalert2';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Translators } from '../shared/translators.service';
import { StorageService } from '../shared/storage.service';
import { APP_CONFIG, IAppConfig } from '../../app.config/app.config';
import { promise } from 'protractor';
import { MensajesBackendService } from '@services/msj/mensajes-backend';
import { UsuarioService } from '../shared/usuario.service';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string = null;
  emailIngresado: string = null;
  idUsuario: string = null;
  usuario: User;
  idioma: '';
  aceptaTerminos: boolean;
  check: boolean;
  private checkTerminos$ = new Subject<Boolean>();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    public guiMsjService: GuiMsjService,
    public guiToolsService: GuiToolsService,
    public translator: Translators,
    private storageService: StorageService,
    @Inject(APP_CONFIG) private iAppConfig: IAppConfig,
    private mensajesBackendService: MensajesBackendService,

  ) {
    this.checkTerminos$ = new Subject();
  }

  getCustomBody(): any {
    const data = { data: '' };
    return data;
  }

  setCheck(acepta: boolean) {
    this.check = acepta;
    this.checkTerminos$.next(acepta);
  }

  getCheck$(): Observable<Boolean> {
    return this.checkTerminos$.asObservable();
  }

  async getIdiomaStorage() {
    await this.storageService.getIdioma().then((idioma: any) => {
      this.idioma = idioma;
      return;
    });
  }

  async validateTerms(): Promise<boolean> {
    const user = await this.getUserLogon();
    this.aceptaTerminos = user.aceptaTerminos;
    return this.aceptaTerminos;
  }

  async validateToken(): Promise<boolean> {

    await this.loadJwToken();

    if (!this.token) {
      this.router.navigateByUrl('/home');
      return Promise.resolve(false);
    }

    // await this.validateTerms();

    return new Promise<boolean>(resolve => {
      const headers = new HttpHeaders({
        'x-token': this.token
      });

      this.httpClient.get(`${URL_BACKEND}/auth/verificar`, { headers })
        .subscribe((resp: any) => {
          if (resp.ok) {
            resolve(true);
          } else {
            this.router.navigateByUrl('/profile-authentication');
            resolve(false);
          }
        }, error => {
          localStorage.clear();
          this.router.navigateByUrl('/profile-authentication');
        });
    });
  }

  async getIdentificacion(): Promise<string> {
    const identificacion = localStorage.getItem('identificacion');
  
    if (identificacion === null) {
      this.storageService.clearAllStorage();
      await this.router.navigateByUrl('/profile-authentication');
      await this.guiMsjService.msjFormSubmit('tokenVencido');
    }
  
    return identificacion; // Devuelve 'null' si no hay identificación.
  }
  

  async setIdentificacion(identificacion: string) {
    this.emailIngresado = identificacion;
    localStorage.setItem('identificacion', identificacion);
  }

  async getEmail(): Promise<string> {
    return localStorage.getItem('email') || null;
  }

  async setEmail(email: string) {
    this.emailIngresado = email;
    localStorage.setItem('email', email);
  }

  async getJwToken(): Promise<string> {
    return localStorage.getItem('signo-token') || null;
  }

  async setJWToken(token: string) {
    this.token = token;
    localStorage.setItem('signo-token', token);
  }

  async loadJwToken(): Promise<string> {
    this.token = localStorage.getItem('signo-token') || null;
    return this.token;
  }

  async setIdUsuario(idUsuario: string) {
    this.idUsuario = idUsuario;
    localStorage.setItem('signo-identificador', idUsuario);
  }

  async loadIdUsuario(): Promise<string> {
    this.idUsuario = localStorage.getItem('signo-identificador') || null;
    return this.idUsuario;
  }

  async newPassword(password: string, confirmPassword: string): Promise<boolean> {

    await this.loadJwToken();

    return new Promise(async resolve => {

      const data = {
        password,
        confirmPassword
      };

      const headers = new HttpHeaders({
        'x-token': this.token
      });

      this.httpClient.post<Response>(`${URL_BACKEND}/auth/update`, data, { headers })
        .subscribe(async resp => {

          if (resp) {
            this.router.navigateByUrl('/');
            this.guiMsjService.msjFormSubmit('newPasswordOk');
            resolve(true);
          } else {
            this.guiMsjService.msjFormSubmit('newPasswordError');
          }
        }, async error => {
          this.guiMsjService.msjFormSubmit('errorTokenLogon');
          this.router.navigateByUrl('/profile-authentication');
        });
    });

  }


  // original
  // async getUserLogon(): Promise<User> {


  //   return new Promise(async resolve => {

  //     await this.loadJwToken();
  //     const headers = new HttpHeaders({
  //       'x-token': this.token
  //     });

  //     this.httpClient.get<Response>(`${URL_BACKEND}/auth/getUserLogon`, { headers })
  //       .subscribe(async resp => {
  //         if (resp) {
  //           // Validación del email
  //           const emailIngresado = await this.getEmail();
  //           const emailRespuesta = resp.response.email;
  //           const datosValido = this.validarEmail(emailIngresado, emailRespuesta);
  //           if (datosValido) {
  //             resolve(resp.response);
  //           } else {
  //             this.storageService.clearAllStorage();
  //             this.router.navigateByUrl('/profile-authentication');
  //             await this.guiMsjService.msjFormSubmit('datosInvalidosToken');
  //           }
  //         } else {

  //           this.router.navigateByUrl('/profile-authentication');
  //           resolve(resp.response);
  //         }
  //       }, (error => {
  //         this.guiMsjService.msjFormSubmit('errorTokenLogon');
  //         this.router.navigateByUrl('/profile-authentication');
  //       }));
  //   });
  // }

  async getUserLogon(): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.loadJwToken(),
      });

      const id: string = await this.loadIdUsuario();

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOne/${id}`, { headers }).subscribe(async resp => {
        if (resp) {
          // Validación del email
          const emailIngresado = await this.getEmail();
          const emailRespuesta = resp.response.email;
          const datosValido = this.validarEmail(emailIngresado, emailRespuesta);
          if (datosValido) {
            resolve(resp.response);
          } else {
            this.storageService.clearAllStorage();
            this.router.navigateByUrl('/profile-authentication');
            await this.guiMsjService.msjFormSubmit('datosInvalidosToken');
          }
        } else {

          this.router.navigateByUrl('/profile-authentication');
          resolve(resp.response);
        }
      }, (error => {
        this.guiMsjService.msjFormSubmit('errorTokenLogon');
        this.router.navigateByUrl('/profile-authentication');
      }));
    });
  }

  // REGISTRAR NUEVO USUARIO
  async registerUser(data: Register): Promise<boolean> {

    return new Promise(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.post(`${URL_BACKEND}/auth/register`, data, { headers })
        .subscribe(
          async (resp: Response) => {

            if (resp.response.ok) {

              await this.guiMsjService.msjFormSubmit('createUserOk');
              await this.storageService.setEmailActivar(data.email);
              // this.router.navigate(['/activate-account']);
              resolve(true);

            } else {

              const { msj, msj2 } = this.mensajesBackendService.concatenarMensaje(String(resp.response.message));
              this.guiMsjService.msjBackend(msj || 'error inesperado, contacte administración', msj2);



              // await this.guiMsjService.msjFormSubmit('createUserError');

              resolve(false);

            }
          },
          (error) => {


            Swal.close();

            if (error.error.error.error.code === 11000) {

              this.guiMsjService.msjFormSubmit('duplicateAccount');

            } else {
              this.guiMsjService.msjFormSubmit('createUserError');
            }

            resolve(false);

          });
    });
  }

  // REGISTRAR NUEVO AFILIADO DESDE LA LISTA DE AFILIADOS (ADMINISTRACION)
  async registerAfiliado(data: Register): Promise<boolean> {


    return new Promise(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.post(`${URL_BACKEND}/auth/register`, data, { headers })
        .subscribe(
          async (resp: Response) => {

            if (resp.response.ok) {

              await this.guiMsjService.msjFormSubmit('createAfiliadoOk');
              await this.storageService.setEmailActivar(data.email);
              resolve(true);

            } else {

              await this.guiMsjService.msjFormSubmit('createUserError');

              const { msj, msj2 } = this.mensajesBackendService.concatenarMensaje(String(resp.response.message));
              this.guiMsjService.msjBackend(msj || 'error inesperado, contacte administración', msj2);

              resolve(false);

            }
          },
          (error) => {

            Swal.close();
            if (error.error.error.error.code === 11000) {

              this.guiMsjService.msjFormSubmit('duplicateAccount');

            } else {
              this.guiMsjService.msjFormSubmit('createUserError');
            }

            resolve(false);

          });
    });
  }

  async registerManualUser(user: Register): Promise<boolean> {

    return new Promise(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.post(`${URL_BACKEND}/auth/register`, user, { headers })
        .subscribe(
          async (resp: Response) => {


            if (resp.response["ok"]) {
              await this.guiMsjService.msjFormSubmit('createManulUserOk');
              resolve(true);

            } else {

              // await this.guiMsjService.msjFormSubmit('createManualUserError');

              const { msj, msj2 } = this.mensajesBackendService.concatenarMensaje(String(resp.response.message));
              this.guiMsjService.msjBackend(msj || 'error inesperado, contacte administración', msj2);

              resolve(false);

            }
          },
          (error) => {

            // console.log('Error recibido:', error);

            if (!error["ok"]) {
              this.guiMsjService.msjFormSubmit('errorSendMailRegistroError');
              resolve(false);
              return;
            }

            // este errorr? Mirar con bruno , la logica
            if (error.error.error.error.code === 11000) {

              this.guiMsjService.msjFormSubmit('duplicateAccount');

            } else {

              this.guiMsjService.msjFormSubmit('createUserError');
            }

            resolve(false);

          });
    });
  }

  // Función para validar y repetir la llamada hasta coincidir
  validarEmail(emailIngresado: string, emailRespuesta: string): boolean {
    return emailRespuesta === emailIngresado;
  }


  // ACCESO AL SITIO
  async login(email: string, password: string): Promise<boolean> {
    
    this.storageService.clearAllStorage();

    const data = {
      email,
      password
    };

    return new Promise(async resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.post(`${URL_BACKEND}/auth/login`, data, { headers })
        .subscribe(async (resp: Response) => {

          if (resp.response.ok) {
            Swal.close();
            const emailRespuesta = resp.response.email;

            // Validación del email
            const datosValido = this.validarEmail(email, emailRespuesta);

            if (datosValido) {
              this.setEmail(email);
              await this.setJWToken(resp.response.token);
              await this.setIdUsuario(resp.response._id);
              await this.setIdentificacion(resp.response.identificacion);
              await this.guiMsjService.msjFormSubmit('loginOk');
              this.router.navigate(['/']);
              resolve(true);
            } else {
              this.storageService.clearAllStorage();
              this.router.navigate(['/profile-authentication']);
              await this.guiMsjService.msjFormSubmit('datosInvalidosTokenLogin');
              resolve(false);
            }
          } else {

            Swal.close();

            const { msj, msj2 } = this.mensajesBackendService.concatenarMensaje(String(resp.response.message));
            this.guiMsjService.msjBackend(msj || 'error inesperado, contacte administración', msj2);
            
            resolve(false);

          }

        });
    });
  }

  // SALIR DEL SITIO
  async logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.usuario = null;
    this.storageService.clearAllStorage();
    this.router.navigate(['/profile-authentication']);
  }

  // CONFIRMAR CUENTA MEDIANTE PIN
  async confirmAccount(data): Promise<boolean> {

    return new Promise(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.post(`${URL_BACKEND}/auth/confirmRegister`, data, { headers })
        .subscribe(async (resp: Response) => {

          if (resp.response.ok) {
            // await this.setJWToken(resp.response.token);
            this.router.navigateByUrl('/profile-authentication');
            await this.guiMsjService.msjFormSubmit('activateAccount');
            resolve(true);
          }

        }, (async error => {
          Swal.close();
          await this.guiMsjService.msjFormSubmit('activateAccountError');
          this.router.navigateByUrl('/activate-account');
          resolve(false);

        }));
    });
  }

  // RESETEAR PASSWORD DE CUENTA
  async resetPassword(email: string): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/auth/passwordRecover/${email}`, { headers })
        .subscribe(async (resp: Response) => {

          if (resp.response.ok) {
            Swal.close();
            await this.guiMsjService.msjFormSubmit('resetPassword');
            this.router.navigateByUrl('/profile-authentication');
            resolve(true);
          } else {
            Swal.close();
            await this.guiMsjService.msjFormSubmit('resetPasswordError');
            this.router.navigateByUrl('/reset-password');
            resolve(false);
          }
        });
    });
  }

  // REENVIAR PIN ACTIVACION DE CUENTA
  async resendPin(email: string): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/auth/pinRegisterRegenerate/${email}`, { headers })
        .subscribe(async (resp: Response) => {

          if (resp.response.ok) {
            Swal.close();

            await this.guiMsjService.msjFormSubmit('pinResendOk');
            this.router.navigateByUrl('/profile-authentication');
            resolve(true);
          } else {
            Swal.close();
            await this.guiMsjService.msjFormSubmit('pinResendError');
            this.router.navigateByUrl('/profile-authentication');
            resolve(false);
          }
        });
    });
  }

  getGuestToken() {

    return new Promise(async resolve => {

      /* LLAVES DE SEGURIDAD */
      const headers = new HttpHeaders({
        clientid: this.iAppConfig.clientid,
        clientsecret: this.iAppConfig.clientsecret,
      });

      this.httpClient.get(`${URL_BACKEND}/auth/getGuestToken`, { headers })
        .subscribe(async (resp: Response) => {
          if (resp.response.ok) {
            await this.setJWToken(resp.response.token);
            this.router.navigate(['/']);
            Swal.close();
            resolve(true);
          } else {
            Swal.close();
            await this.guiMsjService.msjFormSubmit('loginError');
            resolve(false);
          }

        });
    });
  }

  esUsuarioInvitado(): Promise<boolean> {
    return new Promise(async resolve => {
      const usuario: User = await (await this.getUserLogon());
      if (usuario.email === 'getGuestToken@gmail.com') {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

}

