<app-loading *ngIf="!flagLoaded" class="m-5">
</app-loading><br>

<!-- Local SVG Bootstrap Icons for Alerts -->
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </symbol>
</svg>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/usuario-solicitudes">Solicitudes</a></li>
                <li>Solicitud</li>
            </ul>
            <h2>Detalle solicitud</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<div class="products-details-area ptb-100" *ngIf="flagLoaded">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <mat-tab-group animationDuration="0ms">
                    <!-- TAB LABEL 1 -->
                    <mat-tab [label]='"Detalle - Pasos"'>
                        <br>
                        <div class="products-details-desc">
                            <h3>Solicitud</h3>
                            <div class="row">
                                <div class="col-lg-4 col-md-44">
                                    <div class="products-details-desc">
                                        <div class="products-meta">
                                            <span>Trámite: 
                                                <a>{{ actividad.nombre }}</a></span>
                                            <span>Número Solicitud: <a>{{ solicitud._nro }}</a></span>
                                            <span>Fecha solicitud: 
                                                <a>{{ solicitud.createdAt | date:"dd/MM/yy" }}</a></span>
                                            <span *ngIf="sede">
                                                Sede: <a>{{
                                                    sede.nombre }}</a>
                                            </span>
                                            <span>
                                                Titular: <a>{{this.usuario?.fullName}}</a>
                                             </span>
                                            <span>
                                                Benef.: <a>{{ this.beneficiario?.adicional?.fullName ? this.beneficiario.adicional.fullName : this.usuario?.fullName }}</a>
                                            </span>                                            
                                            <span>
                                                Parentesco: <a>{{ this.beneficiario?.parentesco ? this.beneficiario.parentesco : 'Titular' }}</a>
                                            </span>                                                                                      
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-44">
                                    <div class="products-details-desc">
                                        <div class="products-meta">
                                            <span>País: <a>{{
                                                    nombrePais }}</a></span>
                                            <span>Documentación requerida: 
                                                <a>{{ estadoDocumentacion }}</a></span>
                                            <span>Detalle progreso: <a>{{ solicitud.lastState.numberState |
                                                    detalleEstadosPipe }}.</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-44">
                                    <!-- <div class="experience-image"> -->
                                    <img src="assets/img/icon-SM.png" width="60%" alt="image">
                                    <!-- </div> -->
                                </div>
                            </div>

                            <!-- Steps and Button -->
                            <div class="row">
                                <div class="col-lg-8 col-md-4">
                                    <div class="products-add-to-cart">
                                        <!-- estados administrativos -->
                                        <div *ngIf="flagDocumentacionPresentada"
                                            class="alert alert-primary d-flex align-items-center"
                                            role="alert">
                                            <svg class="bi flex-shrink-0 me-2" width="24" height="24"
                                                role="img" aria-label="Info:">
                                                <use xlink:href="#info-fill" />
                                            </svg>Documentos presentados
                                        </div>
                                        <div
                                            class="d-flex flex-column justify-content-center align-items-center">

                                            <div *ngIf="flagEnRevision" class="alert alert-success"
                                                role="alert">
                                                <h4 class="alert-heading">
                                                    <svg class="bi flex-shrink-0 me-2" width="24"
                                                        height="24" role="img" aria-label="Success:">
                                                        <use xlink:href="#check-circle-fill" />
                                                    </svg>
                                                    Documentación en Revisión
                                                </h4>
                                                <p>Su documentación ha sido presentada con éxito y se encuentra en estado de REVISIÓN. Una vez que su documentación haya sido aprobada, recibirá un correo electrónico de confirmación o nos pondremos en contacto con usted para proporcionarle más detalles. ¡Gracias por su colaboración!</p>
                                                <hr>
                                                <div
                                                    class="d-flex align-items-center justify-content-center">
                                                    <p class="mb-0 mr-3">Atte <strong>Signo Médico
                                                            🏣</strong></p>
                                                    <a class="btn btn-primary m-lg-3" routerLink="/"
                                                        role="button">Aceptar</a>
                                                </div>
                                            </div>

                                            <span *ngIf="flagEnAuditoria" class="alert alert-info"
                                                role="alert">La documentación esta en auditoría médica...</span>

                                            <span *ngIf="flagAprobado" class="alert alert-success"
                                                role="alert">Aprobado - Felicitaciones!, su trámite fue APROBADO</span>

                                            <span *ngIf="flagRechazada" class="alert alert-danger"
                                                role="alert">Rechazado - Su trámite ha sido RECHAZADO - {{this.solicitud?.lastState?.observaciones}}</span>
                                        </div>

                                        <div *ngIf="flagRechazada" class="alert alert-warning" role="alert">
                                            Por favor, revise su bandeja de entrada de correo electrónico, incluyendo la carpeta de spam, en busca del email con asunto: SIGNO MÉDICO - CAMBIO ESTADO SOLICITUD. Lea detenidamente el contenido del mismo, ya que la razón del rechazo se encuentra en el apartado OBSERVACIONES. En caso de no encontrar el email, le recomendamos que se comunique con su obra social.
                                        </div>

                                        <div *ngIf="flagDocumentacionPresentada || flagEnRevision"
                                            class="alert alert-secondary d-flex align-items-center"
                                            role="alert">
                                            <svg class="bi flex-shrink-0 me-2" width="24" height="24"
                                                role="img" aria-label="Info:">
                                                <use xlink:href="#exclamation-triangle-fill" />
                                            </svg>
                                            <div>Puede cargar más documentos de ser necesario, en la opción
                                                <strong>"Cargar Documentación Extra"</strong>.
                                            </div>
                                        </div>
                                        
                                        <mat-divider></mat-divider>
                                        <br>
                                        <!-- carga documentos opcionales -->
                                        
                                        <app-solicitud-postular-documentacion-incripcion
                                            [activarCargaDocumentosOpcionales]="activarCargaDocumentosOpcionales"
                                            [documentosEstadosSolicitar]="documentosEstadosSolicitar"
                                            [solicitud]="this.solicitud"></app-solicitud-postular-documentacion-incripcion>
                                        <!-- fin carga documentos opcionales -->

                                        <br>

                                        <!--  DETALLE INFORMATIVO PASOS -->
                                        <!-- <div class="experience-area ptb-100 extra-padding">
                                            <div class="container">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="experience-content">
                                                            <span class="sub-title">Realiza tu trámite... Online!</span>
                                                            <h2 class="playfair-display-font">Completa los pasos...</h2>
                                                            <p>Sigue los pasos de la solicitud.</p>
                                                            <ul class="features-list">
                                                                <ul class="features-list">
                                                                    <li><i class="bx bx-check"></i> Carga tu formulario</li>
                                                                    <li><i class="bx bx-check"></i> Envía tu documentación</li>
                                                                    <li><i class="bx bx-check"></i> Espera tu pre-aprobación</li>
                                                                    <li><i class="bx bx-check"></i> Envía tu comprobante</li>
                                                                    <li><i class="bx bx-check"></i> Comienza!</li>
                                                                </ul>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-12">
                                                        <div class="experience-image">
                                                            <img src="assets/img/icon.png" alt="image" width="20">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- DETALLE INFORMATIVO PASOS -->

                                        <!-- texto informacion -->
                                        <div class="features-area pt-100 pb-70 bg-fff8f8">
                                            <div class="container">
                                                <div class="section-title">
                                                    <span class="sub-title">Información</span>
                                                    <h2>Sigue los pasos indicados, para continuar con tu solicitud</h2>
                                                    <p>Ante cualquier duda, puedes comunicarte con nosotros.</p>
                                                </div>
                                                <div class="row justify-content-center">

                                                    <div class="col-lg-4 col-sm-6 col-md-6">
                                                        <div class="features-box">
                                                            <div class="icon">
                                                                <i class="flaticon-shield-1"></i>
                                                            </div>
                                                            <h3>Notificaciones</h3>
                                                            <p>Te notificaremos via email las novedades de tu solicitud.</p>
                                                            <a class="link-btn">Comienza!</a>
                                                            <div class="back-icon">
                                                                <i class="flaticon-shield-1"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- FIN TEXTO DE INFORMACIÓN -->
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <!-- stepper -->
                                    <app-stepper *ngIf="!flagRechazada"
                                        [stepperSet]="this.solicitud.lastState.numberState"
                                        [vertical]="true"></app-stepper>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <!-- TAB LABEL 2 -->
                    <mat-tab [label]='"Documentos enviados"'>
                        <br>
                        <app-documentos-enviados [solicitud]="this.solicitud"
                            [usuario]="this.solicitud.usuario"></app-documentos-enviados>
                    </mat-tab>

                    <!-- TAB LABEL 3 -->
                    <mat-tab
                        [label]='"Cargar Documentación extra"'>
                        <br>
                        <!-- cargar documentos extras -->
                        <br>
                        <mat-card class="example-card">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image"></div>
                                <mat-card-title>Documentos</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <p>ADJUNTAR DOCUMENTO EXTRA</p>
                        
                                <!-- Mensaje de información cuando la carga extra está deshabilitada -->
                                <div *ngIf="solicitud.lastState.numberState == 0" class="alert alert-info" role="alert">
                                    <strong>Información:</strong> Antes de continuar, debe cargar la documentación principal en la opción
                                    "Detalles Pasos" de la Solicitud.
                                </div>
                            </mat-card-content>
                            <mat-card-actions>
                                <button (click)="openDialogDocExtra(labelDocExtra, this.idAdjuntoExtra?.properties?._idAdjunto)"
                                    *ngIf="activarCargaExtra && solicitud.lastState.numberState != 0">
                                    Cargar Documentación extra
                                </button>
                                <button *ngIf="solicitud.lastState.numberState == 0" disabled>
                                    Cargar Documentación extra
                                </button>
                            </mat-card-actions>
                        </mat-card>

                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="courses-details-area pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <app-detalle [actividad]=actividad [nombrePais]=nombrePais></app-detalle>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>