<app-loading *ngIf='!this.flagLoaded'>

</app-loading>

<div class="courses-area ptb-100">

    <div class="container">

        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count" *ngIf="this.flagLoaded">
                <p>Encontramos {{this.actividadesDisponibles.length}} <span class="count"></span>Trámites</p>
            </div>
            <!--<div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Ordenar por:</label>
                    <select>
                            <option>Default</option>
                        </select>
                </div>
            </div>-->
            <!-- cambie el translate aca tmb por las dudas -->
        </div>

        <mat-paginator *ngIf="this.flagLoaded" [length]="this.actividadesDisponibles.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page" showFirstLastButtons="false">
        </mat-paginator>

        <div class="row">
            <div class="col-lg-4 col-md-6 animate__animated animate__fadeIn" *ngFor="let actividad of (this.actividadesDisponibles | slice: lowValue : highValue)">
                <app-ofertas-avatar [actividad]="actividad"></app-ofertas-avatar>
            </div>
        </div>

    </div>



</div>