import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Translators } from '../../../services/shared/translators.service';

@Component({
  selector: 'app-btn-ofertas-listar',
  templateUrl: './btn-ofertas-listar.component.html',
  styleUrls: ['./btn-ofertas-listar.component.scss']
})
export class BtnOfertasListarComponent implements OnInit {

  idioma: Subscription;
  
  constructor(
    private translatorService: Translators
    ) { 
      // this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
      //   this.idioma = resp;
      // });
    }

  ngOnInit(): void {
  }

}
