import { Response } from '../../interfaces/interface-bakend/response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GuiMsjService } from './gui-msj.service';
import { AuthService } from '../auth/auth.service';
import { Perfil } from '../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { SignoGecrosAfiliado } from '@interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';

const URL_BACKEND = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuarioLogeado: User;
  usuarioSeleccion: User;
  identificacion: string;
  loggedUser: boolean = true;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    private authService: AuthService,
  ) { }

  setUserLogged(value: boolean): void {
    this.loggedUser = value;
  }

  getUserLogged(): boolean {
    return this.loggedUser;
  }

  // tslint:disable-next-line: variable-name
  updateUser(_id: string, usuario: User): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });
      
      this.httpClient.put<Response>(`${URL_BACKEND}/users/updateOne/${_id}`, usuario, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      }, error => {
        console.log('error', error);
        this.guiMsjService.msjFormSubmit('emailDuplicado');
      });
    });
  }

  getAllUsers(): Promise<User[]> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAll`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getAllUsersGrilla(): Promise<User[]> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAllGrillaUsuarios`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getCountUsers(): Promise<number> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findAll`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response.length);
      });
    });
  }

  getFindOne(id: any): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOne/${id}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
        this.setUsuarioSeleccionado(resp.response);
      });
    });
  }

  getFindOneDatosGecrosLocal(id: any): Promise<SignoGecrosAfiliado> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOne/${id}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response.signoGecrosAfiliado);
      });
    });
  }

  setUsuarioSeleccionado(usuario: User) {
    this.usuarioSeleccion = usuario;
    this.storageService.setUsuario(usuario);
  }

  getUsuarioSeleccionado(): User {
    return this.usuarioSeleccion;
  }

  setIdentificacionUsuario(identificacion: string) {
    this.identificacion = identificacion;
  }

  getIdentificacionUsuario(): string {
    return this.identificacion;
  }

  setUsuarioLogeado(usuario: User) {
    this.usuarioSeleccion = usuario;
    this.storageService.setUsuario(usuario);
  }

  getUsuarioLogeado(): User {
    return this.usuarioLogeado;
  }

  put(id: string, nombre: string, apellido: string, suspendido: boolean) {

    const data = {
      nombrePersona: nombre,
      apellidoPersona: apellido,
      suspendido,
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Usuario modificado satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/usuarios-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };
      });
    });

  }

  updateOnew(data: User) {

    return new Promise(async (resolve, reject) => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put<Response>(`${URL_BACKEND}/users/updateOne/${data._id}`, data, { headers }).subscribe(resp => {
        if (resp) {
          resolve(true);
        } else {
          reject(resp);
        }
        Swal.close();
        // tslint:disable-next-line:no-unused-expression
        (error) => {
          console.log('Error al modificar usuario');
          reject(error);
        };
      });
    });

  }

  updateOne(data: User): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // Verificar si data._id es válido
      if (!data || !data._id) {
        const errorMessage = 'El ID del usuario no es válido';
        console.error(errorMessage);
        reject(errorMessage);
        return;
      }

      try {
        const headers = new HttpHeaders({
          'x-token': await this.authService.loadJwToken(),
        });

        this.httpClient
          .put<Response>(`${URL_BACKEND}/users/updateOne/${data._id}`, data, { headers })
          .subscribe(
            (resp) => {
              if (resp) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            (error) => {
              console.error('Error al modificar usuario:', error);
              reject(error);
            }
          );
      } catch (error) {
        console.error('Error al cargar el token:', error);
        reject(error);
      }
    });
  }

  getFindOneByEmail(email: string): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOneByEmail/${email}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getFindOneByIdentification(identificacion: string): Promise<User> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/findOneByIdentificacion/${identificacion}`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  getExistsTipoAndIdentificacion(tipoIdent: string, ident: string): Promise<boolean> {
    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${URL_BACKEND}/users/existsTipoAndIdentificacion/${tipoIdent}/${ident}`, { headers }).
        subscribe((resp: Response) => {
          resolve(resp.response);
        });
    });
  }

  newPassword(id: string, password: string) {

    const data = {
      password
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Contraseña modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/usuarios-listado']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };

      });
    });
  }

  updatePresentacion(id: string, presentacion: string) {

    const data = {
      presentacion
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.put(`${URL_BACKEND}/users/updateOne/${id}`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Datos modificados satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        this.router.navigate(['/cuenta']);
        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };
      });
    });

  }

  updatePasswordUser(id: string, password: string) {

    const data = {
      id,
      password
    };

    return new Promise(async resolve => {

      const headers = new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.post(`${URL_BACKEND}/users/cambiarPassword/`, data, { headers }).subscribe(resp => {

        Swal.close();

        Swal.fire({
          icon: 'success',
          title: 'Contraseña modificada satisfactoriamente',
          showConfirmButton: false,
          timer: 1100
        });

        resolve(true)
          ,

          // tslint:disable-next-line:no-unused-expression
          (error) => {
            Swal.close();
            Swal.fire('Error al modificar', '', 'error');
            resolve(false);
          };

      });
    });
  }

  async getPerfiles(): Promise<Perfil[]> {

    const headers = new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    return new Promise(resolve => {
      this.httpClient.get<Response>(`${URL_BACKEND}/perfiles/findAllActivos/`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }

  async getParentescosSignoMedico(): Promise<string[]> {

    const headers = new HttpHeaders({
      'x-token': await this.authService.loadJwToken(),
    });

    return new Promise(resolve => {
      this.httpClient.get<Response>(`${URL_BACKEND}/users/parentescosSignoMedico`, { headers }).subscribe((resp: Response) => {
        resolve(resp.response);
      });
    });
  }


  esTitular(afiliado, msj: boolean): boolean {
    if (afiliado.parentesco !== 'TITULAR' && msj) {
        Swal.fire({
            title: 'No es titular, no puede registrarse. Debe ser dado de alta por el titular de la cuenta',
            text: 'Sepa disculpar las molestias',
            icon: 'warning',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        });
    }

    return afiliado.parentesco === 'TITULAR';
  }

  noEsTitular(afiliado, msj: boolean): boolean {    
    
    if (afiliado.parentesco === 'TITULAR' && msj) {
        Swal.fire({
            title: 'El D.N.I ingresado es de un titular, no puede registrar a un Titular como familiar a cargo',
            text: 'Sepa disculpar las molestias',
            icon: 'warning',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        });
    }

    return afiliado.parentesco !== 'TITULAR';
  }

  async getNombreAdjunto(adjunto: string): Promise<string>{
    const posInicial = 0;
    const longitud = adjunto.indexOf('.');
    const idAdjunto = adjunto.substring(posInicial, longitud);

    return new Promise( async resolve => {

      const headers =  new HttpHeaders({
        'x-token': await this.authService.loadJwToken(),
      });

      this.httpClient.get<Response>(`${ URL_BACKEND }/actividad-adjuntos/getNombre/${idAdjunto}`, { headers }).subscribe( resp => {
        resolve(resp.response);
      });
    });
  }


}
