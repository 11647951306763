import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ModalModificarClaveComponent } from './modal-modificar-clave/modal-modificar-clave.component';
import { User } from '../../../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UsuarioService } from '../../../../services/shared/usuario.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { MediaService } from '../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../services/shared/gui-msj.service';
import { Translators } from '../../../../services/shared/translators.service';
import { PerfilesService } from 'src/app/services/perfiles/perfiles.service';
import { GecrosAfiliadosService } from '@services/entidades/gecros-afiliados.service';
import { SignoGecrosAfiliado } from '@interfaces/interface-bakend/entidades/signo-greco-afiliado.interface';
import { SignoSedeService } from '@services/entidades/signo-sedes.service';


@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit {

  @ViewChild('claveModal') claveModal: ElementRef;

  form: FormGroup;
  formImg: FormGroup;
  formPresentacion: FormGroup;
  usuario: User;
  flagLoaded = false;

  // Upload
  flagSelectFile = false;
  selectedFileImg: any;
  value = 0;
  cargandoArchivo = false;
  subscriptionAvance: Subscription;

  idioma: Subscription;

  condition = false;
  fileName = 'No file selected';
  file: File;

  /* ripple */
  centered = false;
  disabled = false;
  unbounded = false;
  /* img portada */
  fileAttr = 'Choose File';
  radius: number;
  color: string;
  imageUrl: string | ArrayBuffer = './assets/img/userDefault.png';

  gecrosAfiliado: SignoGecrosAfiliado;
  flagLoadedGecros: boolean = false;
  sedeAfiliado: string = null;

  constructor(
    private usuariosService: UsuarioService,
    private authService: AuthService,
    private mediaService: MediaService,
    private router: Router,
    private guiMsjService: GuiMsjService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private translatorService: Translators,
    private perfilesService: PerfilesService,
    private gecrosAfiliadosService: GecrosAfiliadosService,
    private signoSedesService: SignoSedeService
  ) {

    // this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
    //   this.idioma = resp;
    // });

    this.getUsuario();
    this.createForm();
    this.createFormImg();
    this.createFormPresentacion();

  }

  ngOnInit(): void {
    this.getEstadoVariables();
    this.perfilesService.allowedByUserLogon(this.router.url);
  }

  cerrar() {
    this.claveModal.nativeElement.style.display = 'none';
  }

  async getUsuario() {
    await this.authService.getUserLogon().then(async (userLogon: User) => {
      this.usuario = userLogon;
    });
  }


  /* CREACION DE FORMULARIOS */
  createForm() {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  createFormImg() {
    this.formImg = new FormGroup({
      imagen: new FormControl('', [Validators.required])
    });
  }

  createFormPresentacion() {
    this.formPresentacion = new FormGroup({
      presentacion: new FormControl()
    });
  }

  /* FIN CREACION DE FORMULARIOS */

  subscribeAvanceUpload() {
    this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
      if (resp) {
        this.value = resp.value;
      } else {
        this.value = 0;
      }
    });
  }

  openFileImgSelected(event) {
    this.selectedFileImg = <File>event.target.files[0];
  }

  async uploadImgPerfil(selectedFile, idUser) {
    this.cargandoArchivo = true;
    await this.mediaService.uploadImgPerfil(selectedFile, idUser).then(async resp => {
      if (resp) {
        if (this.value === 100) { this.cargandoArchivo = false; }
        await this.redirectTo('cuenta');
      } else {
        this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
      }
    });
  }

  /* MODIFICAR CLAVE */

  /* Modal Modificar Clave */
  openDialogModificarClave(): void {

    const dialogRef = this.dialog.open(ModalModificarClaveComponent, {
      width: '800px',
      height: '800px',
      disableClose: true,
      data: 'HOLA MUNDO',
    });

    dialogRef.afterClosed().subscribe(resp => {
    });
  }

  /* MODIFICAR IMAGEN PERFIL */
  openFileSelected(event) {
    this.selectedFileImg = <File>event.target.files[0];
  }

  onChangeFile(file: File, event) {
    if (file) {
      this.openFileSelected(event);
      this.fileName = file.name;
      this.file = file;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = e => {
        this.imageUrl = reader.result;
      }
    }
  }

  async guardarImg() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    this.authService.getUserLogon().then(async (usuario: User) => {
      await this.uploadImgPerfil(this.selectedFileImg, usuario._id);
      await this.guiMsjService.msjFormSubmit('DatosGuardados');
      return;
    });
  }

  /* MODIFICAR PRESENTACION */

  async guardarPresentacion() {
    await this.guiMsjService.msjFormSubmit('Guardando');
    await this.redirectTo('cuenta');
    this.authService.getUserLogon().then(async (usuario: User) => {
      await this.usuariosService.updatePresentacion(
        usuario._id,
        this.formPresentacion.value.presentacion
      );
      await this.guiMsjService.msjFormSubmit('DatosGuardados');

      return;
    });
  }

  async redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  async getEstadoVariables() {
    if (this.getUsuario() === undefined) {
      this.router.navigateByUrl('/');
      return;
    } else {
      await this.getUsuario();
      await this.getAfiliadoGecros();
      this.flagLoaded = true;
    }
  }

  async getAfiliadoGecros() {
    const docAfiliado = this.usuario?.identificacion != undefined ? this.usuario.identificacion : null;
    await this.gecrosAfiliadosService.getAfiliadoByIdentificacion(docAfiliado).then(async (resp) => {
      this.gecrosAfiliado = resp.data[0];
      const codigoAfiliado = resp.data[0]?.numeroAfiliado.substring(0, 3);
      this.sedeAfiliado = (await this.getSedeByCodigoGecros(codigoAfiliado)).toUpperCase();
      this.flagLoadedGecros = true;
    }).catch(()=>{
      console.warn("Error al consultar api gecros");
    });
  }

  async getSedeByCodigoGecros(codigo: string){
    return (await this.signoSedesService.getSedeByCodigo(codigo)).nombre;
  }

}
