<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Contacto</li>
            </ul>
            <h2>Contacto</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png"
             alt="image"
             width="10%"></div>
</div>

<!-- <app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br> -->

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <h3>Actividad Médica</h3>

                    <ul>
                        <li>
                            <div class="d-grid gap-2 col-md col-sm mx-1">

                                <h6><strong class="medical-activities-color">ATENCIÓN DEL AFILIADO:</strong>
                                </h6>
                                <button type="button"
                                        class="default-btn">
                                    <a href="tel:08103334485"> <i
                                           class="fas fa-phone-alt fa-sm"></i>0810-333-4485<span></span>
                                    </a>

                                </button>

                                <h6><strong class="medical-activities-color">AUDITORÍA MÉDICA:</strong></h6>
                                <button type="button"
                                        class="default-btn">
                                    <a href="https://wa.me/+5401167164074"
                                       target="_blank"><i class="fab fa-whatsapp fa-lg"
                                           style="color: #12e220;"></i>Escribinos por
                                        Whatsapp<span></span></a>
                                </button>

                                <br>
                                
                                <hr>

                                <h6><strong class="emergency-color">EMERGENCIAS Y URGENCIAS 24HS:</strong>
                                </h6>
                                <button type="button"
                                        class="default-btn emergency-btn">
                                    <div class="container">

                                        <a href="tel:08003331147"> <i
                                               class="fas fa-phone-alt fa-sm"></i>0800-333-1147
                                            <span></span></a>
                                    </div>
                                </button>

                            </div>
                        </li>
                    </ul>

                    <br>

                    <hr>

                    <!-- SEDE ASOCIADO AL AFILIADO -->

                    <div class="attention-partner"
                         *ngIf="this.sede && this.sede !== null">
                        <h3>Atención al Socio</h3>
                        <ul>
                            <li>
                                <div class="d-grid gap-2 col-md col-sm mx-1 affiliate-headquarters">

                                    <h5 style="color: #2f4f4f"><strong>{{this.sede.nombre}}</strong>
                                    </h5>
                                    <h6 class="headquarters-info"
                                        *ngIf="this.sede?.direccion"><strong>Dirección:
                                        </strong>{{this.sede?.direccion}}
                                    </h6>
                                    <h6 class="headquarters-info"
                                        *ngIf="this.sede?.email"><strong>Email:
                                        </strong>{{this.sede?.email}}
                                    </h6>
                                    <h6 class="headquarters-info"
                                        *ngIf="this.sede?.horario"><strong>Horario:
                                        </strong>{{this.sede?.horario}}
                                    </h6>
                                    <button type="button"
                                            class="default-btn">
                                        <a [href]="'tel:' + this.sede.telefono1"> <i
                                               class="fas fa-phone-alt fa-sm"></i>{{this.sede.telefono1}}<span></span>
                                        </a>
                                    </button>
                                    <button *ngIf="this.sede?.telefono2"
                                            type="button"
                                            class="default-btn">
                                        <a [href]="'tel:' + this.sede?.telefono2"> <i
                                               class="fas fa-phone-alt fa-sm"></i>{{this.sede?.telefono2}}<span></span>
                                        </a>
                                    </button>
                                    <button type="button"
                                            class="default-btn">
                                        <a [href]="'https://wa.me/'+this.sede?.whatsapp"
                                           target="_blank"><i class="fab fa-whatsapp fa-lg"
                                               style="color: #12e220;"></i>Escribinos por
                                            Whatsapp<span></span></a>
                                    </button>

                                </div>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form-email"
                     *ngFor="let Image of bgImage;"
                     style="background-image: url({{Image.img}});">
                    <h3>Complete el siguiente formulario para contactarnos</h3>

                    <form [formGroup]="form"
                          (ngSubmit)="submit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input formControlName="email"
                                           type="text"
                                           [class.is-invalid]="emailNoValido"
                                           class="form-control-email"
                                           id="email"
                                           placeholder="Ingrese su correo electrónico">
                                    <small *ngIf="emailNoValido"
                                           style="color:#1F1F32;">Ingrese un correo electrónico válido</small>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="mensaje"
                                              cols="30"
                                              rows="5"
                                              class="text-area"
                                              placeholder="Ingrese su mensaje"></textarea>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <app-modal-legal [CFA]="true" [TC]="true"></app-modal-legal>
                            </div> -->


                            <div class="form-group">

                                <mat-checkbox formControlName="clausula"><em>He leído y acepto los Términos y Condiciones
                                        - <a (click)="openBlank('TC')"
                                           class="link"
                                           style="text-decoration: none;">Términos y condiciones</a>
                                    </em>
                                </mat-checkbox>

                            </div>
                            <div class="form-group">

                                <mat-checkbox formControlName="proteccionDeDatos"><em>Autorizo al tratamiento de mis datos para poder recibir información por medios electrónicos
                                    </em></mat-checkbox>
                                - <a (click)="openBlank('CFA')"
                                   class="link"
                                   style="text-decoration: none;">Cláusula Registro</a>

                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit"
                                        [disabled]="!form.valid"
                                        class="default-btn"
                                        disabled>Enviar formulario<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.958054307627!2d-58.46945512425863!3d-34.6305002729447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc988ec57119f%3A0xb6720547b143969b!2sCulpina%2031%2C%20C1406ENA%20CABA!5e0!3m2!1ses!2sar!4v1698321668755!5m2!1ses!2sar"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>