import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MensajesBackendService {

  constructor(
    private router: Router,
  ) { }
  
  getErrorMessage(errorMessage: string): string {
    let customErrorMessage = '';
  
    switch (true) {
      case errorMessage.includes('E11000 duplicate key'):
        customErrorMessage = 'El elemento que quiere cargar ya existe.';
        break;
      // Agrega más casos según los diferentes tipos de errores que quieras manejar
      default:
        customErrorMessage = 'Ocurrió un error al procesar la solicitud.';
        break;
    }
  
    return customErrorMessage;
  }

  private obtenerMensajeEmailExistente(mensaje: string): string {
    if (mensaje === 'EMAIL_EXISTENTE') {
        return 'El email ya se encuentra registrado, por favor intente con uno diferente';
    }
    return '';
  }

  private obtenerMensajeEmailNoVerificado(mensaje: string): string {
    if (mensaje === 'EMAIL_NO_VERIFICADO') {
        return 'Para acceder a su cuenta, es necesario confirmar su dirección de correo electrónico. Le hemos enviado un código de verificación (PIN) a su email. Si aún no ha confirmado su cuenta, por favor revise su bandeja de entrada o carpeta de correo no deseado y siga las instrucciones del mensaje.';
    }
    return '';
  }
  

  concatenarMensaje(mensajeBackend: string): { msj: string; msj2: string } {
    const asteriskIndex = mensajeBackend.indexOf('*');

    const mensajesValidos = [
      'EMAIL_EXISTENTE',
      'CONFIRM_REGISTER_ERROR',
      'CONFIRM_REGISTER_OK',
      'CUENTA_SUSPENDIDA',
      'CUENTA_YA_VERIFICADA',
      'EMAIL_INEXISTENTE',
      'EMAIL_NO_VERIFICADO',
      'LOGIN_SUCCESSFULLY',
      'LOGOUT_SUCCESSFULLY',
      'PASSWORD_INCORRECTO',
      'PASSWORD_RECUPERADO_OK',
      'PASSWORD_UPDATED',
      'SEND_PIN_OK',
      'duplicate',
    ];

    if (asteriskIndex !== -1) {

      const mensajeSinPrefijo = mensajeBackend.substring(asteriskIndex + 1); // Obtiene parte después del asterisco

      if (mensajesValidos.includes(mensajeSinPrefijo)) {

        const formattedMessage = mensajeSinPrefijo
          .toLowerCase() // Convertir todo a minúsculas
          .replace(/_/g, ' ') // Reemplazar guiones bajos con espacios
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Convertir primera letra a mayúscula
        
        // Mensajes adicionales
        let mensajeAdicional = '';

        if (mensajeSinPrefijo === 'EMAIL_EXISTENTE') {
          mensajeAdicional = this.obtenerMensajeEmailExistente(mensajeSinPrefijo);
        } else if (mensajeSinPrefijo === 'EMAIL_NO_VERIFICADO') {
          mensajeAdicional = this.obtenerMensajeEmailNoVerificado(mensajeSinPrefijo);
        }
        
        return { 
          msj: formattedMessage,
          msj2: mensajeAdicional || '', // Añadir mensaje adicional si existe
        };

      }
      else {
            return { msj: 'Mensaje no válido', msj2: '' };
        }
    } 
    else {
      return { msj: 'Formato de mensaje incorrecto', msj2: '' };
    }
  }

  showSuccessMessage(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Inicio Exitoso!',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      this.router.navigate(['/']);
    });
  }

  showErrorMessage(message: string): void {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: message || 'Error General ' + message,
      showConfirmButton: true,
      // timer: 1500
    });
  }

  showRegistrationSuccess(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Registro Exitoso!, se ha enviado un pin de confirmacion a la cuenta de email creada',
      showConfirmButton: true,
    });
  }

  showRegistrationPinError(message: string): void {
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'No se pudo activar su PIN!, su PIN se encuentra activo. Puede reiniciar su clave si precisa acceder.',
      showConfirmButton: true,
    });
  }
  
  showErrorMessageRegistration(): void {
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'Registro Error!, se produjo un error al querer registrar',
      showConfirmButton: true,
      timer: 1500
    });
  }
}
