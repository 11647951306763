<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>Mis Solicitudes</li>
            </ul>
            <h2>Mis Solicitudes</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="profile-area pb-70">
    <div class="container">

        <div class="profile-courses-quizzes">
            <h3>Aquí encontrará el progreso de las solicitudes realizadas</h3>
            <hr>

            <mat-form-field>
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
            </mat-form-field>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Column -->
                    <ng-container matColumnDef="tramite">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trámite</th>
                        <td mat-cell *matCellDef="let element"> {{element.tramite }} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="_nro">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. </th>
                        <td mat-cell *matCellDef="let element"> {{element._nro}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="fecha">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha solicitud </th>
                        <td mat-cell *matCellDef="let element"> {{element.fecha | date:"dd/MM/yy"}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="progreso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Progreso </th>
                        <td mat-cell *matCellDef="let element"> {{element.progreso | estadosPipe}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="beneficiarioNombre">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Beneficiario </th>
                        <td mat-cell *matCellDef="let element"> {{element.beneficiarioNombre}} </td>
                    </ng-container>

                    <!-- Column -->
                    <ng-container matColumnDef="beneficiarioParentezco">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Parentezco </th>
                        <td mat-cell *matCellDef="let element"> {{element.beneficiarioParentezco}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ver">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let element">
                            <button (click)="verSolicitud(element)" mat-raised-button color="accent">Ver/Cargar</button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="openPdf">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Auto. </th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                (click)="openDialogPdf(usuario._id, element.file[0], usuario.fullName)"
                                mat-raised-button
                                color="primary"
                                [disabled]="!element.file.length"
                            >
                                Ver Autorización
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="descargar">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Auto. </th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                (click)="download(usuario._id, element.file[0], usuario.fullName)"
                                mat-raised-button
                                color="primary"
                                [disabled]="!element.file.length"
                            >
                                Desc. Autorización.
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'red-row': row.progreso === 5, 'green-row': row.progreso === 4 }" class="example-element-row"></tr>

                </table>
            </div>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>