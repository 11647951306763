<div class="courses-details-desc">
    <ngx-tabset>
        <ngx-tab [tabTitle]='"Descripción general"'>
            <div class="courses-overview">
                <h3>Descripción del trámite</h3>
                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.descripcion }}
                </p>

                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.detalle }}
                </p>

                <h3>Requerimientos</h3>
                <p style="text-align: justify;" class="angular-with-newlines">
                    {{ actividad.requisitos }}
                </p>

            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]='"Documentación"'>
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>Documentación requerida</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;"><strong>{{ actividad.documentacionRequerida }}</strong></p>
                        </li>
                    </ul>
                </div>
                <div class="courses-overview">
                    <h3>Detalle de la documentación</h3>
                    <ul *ngFor="let doc of actividad.documentosEstadosSolicitar">
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ doc.documento.nombre }}</strong></p>
                            <p>{{doc.documento.descripcion}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]='"¡Importante!"'>
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>Información importante</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ actividad.importante }}</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

        <ngx-tab [tabTitle]='"Finalizando"'>
            <div class="courses-instructor">
                <div class="courses-overview">
                    <h3>Al finalizar</h3>
                    <ul>
                        <li>
                            <p style="text-align: justify;" class="angular-with-newlines"><strong>{{ actividad.finalizando }}</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </ngx-tab>

    </ngx-tabset>
</div>