import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from '@interfaces/interface-bakend/seguridad/users/user.interface';
import { AuthService } from '@services/auth/auth.service';
import { UserEntreUserService } from '@services/shared/user-entre-user.service';
import { UsuarioService } from '@services/shared/usuario.service';
import { PerfilesService } from '@services/perfiles/perfiles.service';
import { GuiMsjService } from '@services/shared/gui-msj.service';
import { PreviousRouteService } from '@services/shared/previous-route.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-familiares',
  templateUrl: './familiares.component.html',
  styleUrls: ['./familiares.component.scss']
})
export class FamiliaresComponent implements OnInit {
  afiliadoLogged: User;
  adicionalId: string;
  grupoFamiliar: any = [];
  flagTitular = false;

  flagLoading = true;

  flagDataGecros: boolean = false;

  routes: { url: string, label: string }[] = [];

  bgImage = [
    {
      background: 'assets/img/signo-medico/profile-family.jpg',
    }
  ]

  constructor(
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private authService: AuthService,
    private userEntreUserService: UserEntreUserService,
    private perfilesService: PerfilesService,
    private guiMsjService: GuiMsjService,
    private usuarioService: UsuarioService
  ) {
    this.loadGrupoFamiliar();
  }

  ngOnInit(): void {
    this.perfilesService.allowedByUserLogon(this.router.url);
    this.previousRouteService.getRoutes().subscribe(routes => {
      this.routes = routes;
    }).unsubscribe();
  }

  async loadGrupoFamiliar() {
    try {

      //Se obtiene al afiliado 
      this.afiliadoLogged = await this.authService.getUserLogon();

      console.log(' this.afiliadoLogged',  this.afiliadoLogged);
      this.flagTitular = this.titular(this.afiliadoLogged);

      if (!this.afiliadoLogged) {
        throw new Error('No se encontro el afiliado.');
      }

      /**
       ** Adicionales
       */

      const beneficiaries = await this.userEntreUserService.findGrupoFamiliar(this.afiliadoLogged._id);

      console.log('beneficiaries', beneficiaries);

      this.adicionalId = beneficiaries[0]?.adicional?._id; //Obtengo el id del adicional para que me traiga al titular a cargo si existe

      if (beneficiaries.length > 0) {

        for (const beneficiary of beneficiaries) {
          const { adicional } = beneficiary;

          //Adicionales del afiliado seleccionado
          if (adicional) {
            const { nombrePersona, apellidoPersona, signoGecrosAfiliado, identificacion } = adicional;
            const parentesco = beneficiary.parentesco;
            // const parentesco = signoGecrosAfiliado?.parentesco; // parentezo gecros
            const dataAdicional = {
              nombre: nombrePersona,
              apellido: apellidoPersona,
              parentesco: parentesco,
              identificacion: identificacion
            }
            this.grupoFamiliar.push(dataAdicional);
          }
        }
      } else {
        this.flagDataGecros = false;
      }

      /**
      ** Fin Adicionales
      */

      /**
       *? Titulares
       */

      if (this.adicionalId) {
        const headlines = await this.userEntreUserService.findTitular(this.adicionalId);

        if (headlines.length > 0) {
          for (const headline of headlines) {
            const { titular } = headline;

            if (titular) {
              const { nombrePersona, apellidoPersona, signoGecrosAfiliado, identificacion } = titular;
              const parentesco = signoGecrosAfiliado?.parentesco;
              const dataTitular = {
                nombre: nombrePersona,
                apellido: apellidoPersona,
                parentesco: parentesco,
                identificacion: identificacion
              }
              this.grupoFamiliar.push(dataTitular);
            }
          }
        }
      } else {
        this.flagDataGecros = false;
      }

      /**
      *? Fin Titulares
      */

      this.grupoFamiliar = this.grupoFamiliar.reverse();

      if (this.grupoFamiliar.length > 0) {
        this.flagDataGecros = true;
      }

      this.flagLoading = false;


    } catch (error) {
      console.error("Se ha producido un error al cargar la lista de familiares vinculados al afiliado.", error);
    }
  }

  async verCredencial(identificacion: string) {
    try {
      // Muestra el mensaje de espera
      await this.guiMsjService.msjFormSubmit('Espere');
      Swal.showLoading();
  
      // Establece el usuario seleccionado y su identificación
      const usuarioSeleccionado = await this.usuarioService.getFindOneByIdentification(identificacion);
      this.usuarioService.setUsuarioSeleccionado(usuarioSeleccionado);
      this.usuarioService.setUserLogged(false);
      this.usuarioService.setIdentificacionUsuario(identificacion);
  
      // Cierra el mensaje de carga y navega a la ruta
      Swal.close();
      this.router.navigateByUrl('/mi-credencial');
      
    } catch (error) {
      console.error("Se ha producido un error al buscar el usuario por identificación.", error);
    }
  }
  

  titular(usuario: User): boolean {
    return usuario?.signoGecrosAfiliado?.parentesco === 'TITULAR';
  }

}
