<!-- ACTIVAR PARA AADIDESS SI LO SOLICITA ESTA EN VARIAS PAG (PROFILE / GYM Y HOME)-->
<!-- <app-cookiebot></app-cookiebot> -->
<div class="container">
    <!--<div>
             <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('en')"><img src="assets/img/flags/usa.png" alt="English" width="20"> EN</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('es')"><img src="assets/img/flags/espana.png" alt="Español" width="20"> ES</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ca')"><img src="assets/img/flags/catalunya.png" alt="Catala" width="20"> CA</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('it')"><img src="assets/img/flags/italia.png" alt="Italiano" width="20"> IT</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('ar')"><img src="assets/img/flags/arabia-saudita.png" alt="Arab" width="20"> AR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('fr')"><img src="assets/img/flags/francia.png" alt="French" width="20"> FR</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('de')"><img src="assets/img/flags/alemania.png" alt="German" width="20"> DE</button>
            <button class="btn-tabla-main-color ms-3 mt-3" (click)="useLanguage('br')"><img src="assets/img/flags/brasil.png" alt="Portugués" width="20"> PT </button> 
        </div> -->
    <div class="page-title-content">
        <h2>
            <a routerLink="/home"><img src="assets/img/logo-signomedico-1.png"
                     alt="signomedico"
                     width="50%"></a><br><br>Bienvenido a Signo Médico
        </h2>
    </div>
</div>

<div class="profile-authentication-area ptb-70 mt-5 ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form animate_animated animate_fadeIn"><br>
                    <h2> Iniciar Sesión </h2>
                    <form [formGroup]="loginForm"
                          (ngSubmit)="login()">

                        <div class="form-group">
                            <label>Email</label>
                            <input formControlName="email"
                                   type="email"
                                   class="form-control text-lowercase"
                                   placeholder="Ingrese su correo electrónico"
                                   [class.is-invalid]="emailAccess">
                            <small *ngIf="emailAccess"
                                   class="text-danger">Ingrese su correo electrónico</small>
                        </div>

                        <div class="form-group inner-addon right-addon">
                            <label>Contraseña</label>
                            <div class="input-group">
                                <input formControlName="password"
                                       [type]="showPasswordLogin ? 'text' : 'password'"
                                       class="form-control"
                                       placeholder="Ingrese su contraseña"
                                       [class.is-invalid]="passwordAccess">
                                <i alt="show"
                                   class="far fa-eye eye-show"
                                   (click)="showPasswordLogin = !showPasswordLogin"
                                   [class.hide]="showPasswordLogin"></i>
                                <i alt="hide"
                                   class="far fa-eye-slash eye-hide"
                                   (click)="showPasswordLogin = !showPasswordLogin"
                                   [class.hide]="!showPasswordLogin"></i>
                            </div>
                            <small *ngIf="passwordAccess"
                                   class="text-danger">Ingrese su contraseña</small>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox"
                                           id="test2">
                                    <label for="test2">Recordarme</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/resend-pin"
                                   class="lost-your-password"
                                   style="text-decoration: none;">Reenviar PIN</a> - <a routerLink="/reset-password"
                                   class="lost-your-password"
                                   style="text-decoration: none;">Olvidé mi contraseña</a> - <a
                                   routerLink="/activate-account"
                                   class="lost-your-password"
                                   style="text-decoration: none;">Activar cuenta mediante PIN</a>
                            </div>
                        </div>

                        <button type="submit"
                                [disabled]="!loginForm.valid"
                                disabled><i class="fas fa-sign-in-alt"></i> Iniciar Sesión</button>
                        <button type="submit"
                                *ngIf="loadingForm"
                                [disabled]="true"
                                class="btn btn-primary submit-btn btn-block">
                            <i class="fas fa-sign-in-alt"></i>
                            Ingresando al sistema
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>Registro</h2>

                    <div class="alert">
                        <strong>¡Importante!</strong> Por favor, registre su cuenta, le solicitamos
                        amablemente que verifique tanto su bandeja de entrada como su carpeta de correo
                        no deseado. Esto se debe a que nuestro sistema enviará un PIN importante para
                        validar la cuenta que ha sido creada.
                    </div>
                    <div class="info">
                        <strong>¡Información!</strong> Al completar el registro recibirá
                        un correo electrónico que contendrá un PIN de validación. Haga clic en el enlace
                        del <strong>PIN</strong> proporcionado para activar y comenzar a utilizar su
                        cuenta.
                    </div>

                    <form [formGroup]="registerForm"
                          (ngSubmit)="registerUser()">
                        <div class="box-input-shadow-dni">
                            <div class="form-group inner-addon right-addon">
                                <label>Número de Identificación - D.N.I</label>
                                <div class="input-group">
                                    <input type="text"
                                           formControlName="identificacion"
                                           class="form-control"
                                           (input)="buscarAfiliadoGecros()"
                                           (paste)="$event.preventDefault()"
                                           [(ngModel)]="identificacion"
                                           placeholder="Escriba su número de identificación"
                                           [class.is-invalid]="getIdentificacionNoValida">
                                    <div *ngIf="(!flagDataGecros && viewSpinner)"
                                         class="loader-container">
                                        <strong>Buscando Afiliado</strong>
                                        <i class="loader"></i>
                                    </div>
                                </div>
                                <mat-error *ngIf="getIdentificacionNoValida">Número de Identificación - D.N.I</mat-error>
                                <br>
                                <div *ngIf="showTimeoutMessage && (!flagDataGecros && !dataGecros)"
                                     class="text-danger">
                                    No se han encontrado sus datos de afiliado, para registrarse primero
                                    debe afiliarse a
                                    <strong><a href="https://gecros.com/"
                                           target="_blank"
                                           rel="noreferrer">Gecros</a></strong>.
                                </div>
                            </div>
                        </div>


                        <div class="form-group">
                            <div class="row mb-4">
                                <div class="col-lg-6 col-xs-12">
                                    <div class="form-outline">
                                        <label>Escriba su nombre completo</label>
                                        <input type="text"
                                               formControlName="nombrePersona"
                                               placeholder="Escriba su nombre completo"
                                               [class.is-invalid]="getNombreNoValido"
                                               class="form-control"
                                               style="margin-right: 3px;">
                                        <small style="margin-right: 30px;"
                                               *ngIf="getNombreNoValido"
                                               class="text-danger">Escriba su nombre completo</small>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-xs-12">
                                    <div class="form-outline">
                                        <label>Escriba su apellido completo</label>
                                        <input type="text"
                                               formControlName="apellidoPersona"
                                               placeholder="Escriba su apellido completo"
                                               [class.is-invalid]="getApellidoNoValido"
                                               class="form-control">
                                        <small *ngIf="getApellidoNoValido"
                                               class="text-danger">Escriba su apellido completo</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Correo Electrónico</label>
                            <input formControlName="email"
                                   type="email"
                                   class="form-control text-lowercase"
                                   placeholder="Ingrese su correo electrónico"
                                   [class.is-invalid]="emailNoValido">
                            <small *ngIf="emailNoValido"
                                   class="text-danger">Ingrese un correo electrónico válido</small>
                        </div>
                        <div class="form-group">
                            <label>Confirme correo electrónico</label>
                            <input formControlName="confirmEmail"
                                   type="email"
                                   class="form-control text-lowercase"
                                   placeholder="Confirme el correo electrónico"
                                   [class.is-invalid]="confirmEmailNoValido">
                            <small *ngIf="confirmEmailNoValido"
                                   class="text-danger">Los correos electrónicos no coinciden</small>
                        </div>

                        <div class="form-group inner-addon right-addon">
                            <label>Contraseña</label>
                            <div class="input-group">
                                <input formControlName="password"
                                       [type]="showPasswordRegister ? 'text' : 'password'"
                                       class="form-control"
                                       placeholder="Ingrese su contraseña"
                                       [class.is-invalid]="passwordNoValido"
                                       (cut)="$event.preventDefault()"
                                       (copy)="$event.preventDefault()"
                                       (paste)="$event.preventDefault()">
                                <i alt="show"
                                   class="far fa-eye eye-show"
                                   (click)="showPasswordRegister = !showPasswordRegister"
                                   [class.hide]="showPasswordRegister"></i>
                                <i alt="hide"
                                   class="far fa-eye-slash eye-hide"
                                   (click)="showPasswordRegister = !showPasswordRegister"
                                   [class.hide]="!showPasswordRegister"></i><br>
                            </div>
                            <small *ngIf="passwordNoValido" class="text-danger">
                                Por favor, elige una contraseña que cumpla con los siguientes requisitos:<br>
                                - Debe tener 8 caracteres de longitud.<br>
                                ¡Gracias!
                            </small>                                                   
                        </div>

                        <div class="form-group inner-addon right-addon">
                            <label>Confirme contraseña</label>
                            <div class="input-group">
                                <input formControlName="confirmPassword"
                                       [type]="showPasswordConfirm ? 'text' : 'password'"
                                       class="form-control"
                                       placeholder="Confirme contraseña"
                                       [class.is-invalid]="confirmPasswordNoValido">
                                <i alt="show"
                                   class="far fa-eye eye-show"
                                   (click)="showPasswordConfirm = !showPasswordConfirm"
                                   [class.hide]="showPasswordConfirm"></i>
                                <i alt="hide"
                                   class="far fa-eye-slash eye-hide"
                                   (click)="showPasswordConfirm = !showPasswordConfirm"
                                   [class.hide]="!showPasswordConfirm"></i><br>
                            </div>
                            <small *ngIf="confirmPasswordNoValido" class="text-danger">
                                Por favor, asegúrate de que las contraseñas coincidan.
                            </small>
                            
                        </div>

                        <p class="description">
                            Por favor, elige una contraseña que cumpla con los siguientes requisitos:
                            - Debe tener al menos 6 caracteres de longitud.                          
                            ¡Gracias!
                        </p>
                        

                        <!-- CERTIFICACION -->

                        <!-- <div class="form-group">
                                <app-modal-legal [CFR]="true" [TC]="true"></app-modal-legal>
                            </div> -->

                        <div class="form-group">
                            <input type="checkbox"
                                   formControlName="proteccionDeDatos"
                                   style="margin-right: 5px !important;"> <em>He leído y acepto la cláusula de Información sobre Protección de Datos
                                - <a (click)="openBlank('CFR')"
                                   class="link"
                                   style="text-decoration: none;">Cláusula Registro</a></em>
                        </div>

                        <div class="form-group">
                            <input type="checkbox"
                                   formControlName="clausula"
                                   style="margin-right: 5px !important;"> <em>Autorizo al tratamiento de mis datos para poder recibir información por medios electrónicos</em>
                        </div>

                        <div class="form-group">
                            <input type="checkbox"
                                   formControlName="aceptaTerminos"
                                   style="margin-right: 5px !important;"> <em>He leído y acepto los Términos y Condiciones
                                - <a (click)="openBlank('TC')"
                                   class="link"
                                   style="text-decoration: none;">Términos y condiciones</a></em>
                        </div>

                        <!-- <div class="form-group">
                                <input type="checkbox" formControlName="consentimiento" style="margin-right: 5px !important;"> <em>Otorgo mi consentimiento explícito para que SIGNO MÉDICO realice transferencias internacionales de datos</em>
                            </div> -->

                            <!-- le saque el pipe del translate a este tambien -->

                        <!-- FIN CERTIFICACION -->

                        <div class="form-group">
                            <ngx-recaptcha2 #captchaElem
                                            [siteKey]="siteKey"
                                            formControlName="recaptcha">
                            </ngx-recaptcha2>
                        </div>

                        <!-- <button *ngIf="registerForm.valid" type="submit" ><i class="fas fa-sign-in-alt"></i> Registrarme</button> -->
                        <!-- le saque el pipe del translate a este tambien -->

                        <button [disabled]="!flagFormaValido"
                                [ngClass]="{'custom-disabled': !registerForm.valid}"> Registrarme</button>
                        <br>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>