import { Injectable } from '@angular/core';
import { Curso } from '../../interfaces/interface-bakend/cursos/curso.interface';
import { PaisDisponible } from '../../interfaces/interface-bakend/shared/paises/pais-disponible/pais-disponible.interface';
import { Examen } from '../../interfaces/interface-bakend/cursos/examen.interface';
import { Catalogo } from '../../interfaces/interface-bakend/shared/catalogo/catalogo.interface';
import { Actividad } from '../../interfaces/interface-bakend/ofertas-laborales/actividad.interface';
import { Solicitud } from '../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { Materia } from '../../interfaces/interface-bakend/cursos/materia.interface';
import { Leccion } from '../../interfaces/interface-bakend/cursos/leccion.interface';
import { User } from '../../interfaces/interface-bakend/seguridad/users/user.interface';
import { UserEntreUser } from '@interfaces/interface-bakend/seguridad/users/user-entre-user.interface';
import { ActividadDAdjunto } from '../../interfaces/interface-bakend/ofertas-laborales/actividad-adjuntos.interface';
import { Invoice } from 'src/app/interfaces/interface-bakend/invoices/invoices.interfaces';
import { Perfil } from '../../interfaces/interface-bakend/seguridad/acl/perfil.interface';
import { ExamenEvaluacion } from 'src/app/interfaces/interface-bakend/cursos/examen-evaluacion.interface';
import { Titulo } from 'src/app/interfaces/interface-bakend/cursos/titulo.interface';
import { Mesa } from 'src/app/interfaces/interface-bakend/cursos/mesa.interface';
import { Gesto } from 'src/app/interfaces/interface-bakend/cursos/gesto.interface';
import { SignoPlan } from '@interfaces/interface-bakend/entidades/signo-plan.interface';
import { SignoEmpresa } from 'src/app/interfaces/interface-bakend/entidades/signo-empresa.interface';
import { SignoSede } from 'src/app/interfaces/interface-bakend/entidades/signo-sede.interface';
import { SignoDoctor } from 'src/app/interfaces/interface-bakend/entidades/signo-doctor.interface';
import { SignoLocalidad } from 'src/app/interfaces/interface-bakend/entidades/signo-localidad.interface';
import { SignoCentroMedico } from 'src/app/interfaces/interface-bakend/entidades/signo-centro-medico.interface';

const PAIS = 'PAIS';
const ACTIVIDAD = 'ACTIVIDAD';
const CURSO = 'CURSO';
const TITULO = 'TITULO';
const SOLICITUD = 'SOLICITUD';
const MATERIA = 'MATERIA';
const MESA = 'MESA';
const GESTO = 'GESTO';
const LECCION = 'LECCION';
const TRADUCCION = 'TRADUCCION';
const USUARIO = 'USUARIO';
const EXAMEN = 'EXAMEN';
const EXAMEN_FINALIZADO = 'EXAMEN_FINALIZADO';
const ADJUNTO = 'ADJUNTO';
const CONCEPTO = 'CONCEPTO';
const IDIOMA = 'IDIOMA';
const PERSONA = 'PERSONA';
const INVOICE = 'INVOICE';
const PERFIL = 'PERFIL';
const EMAIL_ACTIVAR = 'EMAIL_ACTIVAR';
const IDIOMA_COOKIEBOT = 'IDIOMA_COOKIEBOT';
const EXAMEN_EVALUADO = 'EXAMEN_EVALUADO';
const SIGNO_PLAN = 'SIGNO_PLAN';
const SIGNO_EMPRESA = 'SIGNO_EMPRESA';
const SIGNO_SEDE = 'SIGNO_SEDE';
const SIGNO_DOCTOR = 'SIGNO_DOCTOR';
const SIGNO_CENTRO_MEDICO = 'SIGNO_CENTRO_MEDICO';
const SIGNO_LOCALIDAD = 'SIGNO_LOCALIDAD';
const USUARIO_ENTRE_USUARIO = 'USUARIO_ENTRE_USUARIO';
const EMAIL_KEY = 'EMAIL_KEY';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() { }

    async setStoreGenerics(nameValue: string, value: string) {
        sessionStorage.setItem(nameValue, value);
    }

    async getStoreGenerics(nameValue: string): Promise<string> {
        return sessionStorage.getItem(JSON.parse(nameValue) || null);
    }

    async clearStorage(nameValue: string) {
        sessionStorage.removeItem(nameValue);
    }

    async setIdiomaCookiebot(nameValue: string) {
        localStorage.setItem(IDIOMA_COOKIEBOT, JSON.stringify(nameValue));
    }

    async getIdiomaCookiebot(): Promise<string> {
        const flag = localStorage.getItem(IDIOMA_COOKIEBOT);
        if (flag !== null) {
            return localStorage.getItem(IDIOMA_COOKIEBOT);
        } else {
            return 'undefined';
        }
    }

    async clearIdiomaCookiebot() {
        localStorage.removeItem(IDIOMA_COOKIEBOT);
    }

    async setPais(pais: PaisDisponible) {
        sessionStorage.setItem(PAIS, JSON.stringify(pais));
    }

    async getPais(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(PAIS) || null);
    }

    async setAdjunto(adjunto: ActividadDAdjunto) {
        sessionStorage.setItem(ADJUNTO, JSON.stringify(adjunto));
    }

    async getConcepto(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(CONCEPTO) || null);
    }

    async setConcepto(concepto: Catalogo) {
        sessionStorage.setItem(CONCEPTO, JSON.stringify(concepto));
    }

    async setActividad(actividad: Actividad) {
        sessionStorage.setItem(ACTIVIDAD, JSON.stringify(actividad));
    }

    async getActividad(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(ACTIVIDAD) || null);
    }

    async getAdjunto(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(ADJUNTO) || null);
    }

    async setSolicitud(solicitud: Solicitud) {
        sessionStorage.setItem(SOLICITUD, JSON.stringify(solicitud));
    }

    async getSolicitud(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(SOLICITUD) || null);
    }

    async setCurso(curso: Curso) {
        sessionStorage.setItem(CURSO, JSON.stringify(curso));
    }

    async setTitulo(titulo: Titulo) {
        sessionStorage.setItem(TITULO, JSON.stringify(titulo));
    }

    async getTitulo(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(TITULO) || null);
    }

    async getCurso(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(CURSO) || null);
    }

    async setMateria(materia: Materia) {
        sessionStorage.setItem(MATERIA, JSON.stringify(materia));
    }

    async setMesa(mesa: Mesa) {
        sessionStorage.setItem(MESA, JSON.stringify(mesa));
    }

    async setGesto(gesto: Gesto) {
        sessionStorage.setItem(GESTO, JSON.stringify(gesto));
    }

    async setLeccion(leccion: Leccion) {
        sessionStorage.setItem(LECCION, JSON.stringify(leccion));
    }

    async setUsuario(usuario: User) {
        sessionStorage.setItem(USUARIO, JSON.stringify(usuario));
    }

    async getUsuario(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(USUARIO) || null);
    }

    // Setear examen finalizado en sessionStorage

    async setExamenFinalizado(examen: Examen) {
        sessionStorage.setItem(EXAMEN_FINALIZADO, JSON.stringify(examen));
    }

    // Obtener el examen finalizado luego de cursarlo
    async getExamenFinalizado(): Promise<string> {
        return sessionStorage.getItem(
            JSON.parse(EXAMEN_FINALIZADO) || null
        );
    }

    async setExamenEvaluado(evaluacion: ExamenEvaluacion) {
        sessionStorage.setItem(EXAMEN_EVALUADO, JSON.stringify(evaluacion));
    }

    getExamenEvaluado() {
        return sessionStorage.getItem(JSON.parse(EXAMEN_EVALUADO) || null);
    }

    async setExamen(examen: Examen) {
        sessionStorage.setItem(EXAMEN, JSON.stringify(examen));
    }

    async getExamen(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(EXAMEN) || null);
    }

    async setIdioma(idioma: string) {
        localStorage.setItem(IDIOMA, JSON.stringify(idioma));
    }

    async getIdioma(): Promise<string> {
        let idioma;
        if (localStorage.getItem(IDIOMA) != null) {
            idioma = localStorage.getItem(IDIOMA || null);
        } else {
            this.setIdioma('es');
            idioma = 'es';
        }
        return idioma;
    }

    async setPersona(persona: User) {
        sessionStorage.setItem(PERSONA, JSON.stringify(persona));
    }

    async getPersona(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(PERSONA) || null);
    }

    async setInvoice(invoice: Invoice) {
        sessionStorage.setItem(INVOICE, JSON.stringify(invoice));
    }

    async getInvoice(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(INVOICE) || null);
    }

    async setPerfil(perfil: Perfil) {
        sessionStorage.setItem(PERSONA, JSON.stringify(perfil));
    }

    async getPerfil(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(PERFIL) || null);
    }

    async setEmailActivar(email: string) {
        sessionStorage.setItem(EMAIL_ACTIVAR, email);
    }

    async getEmailActivar(): Promise<string> {
        return sessionStorage.getItem(EMAIL_ACTIVAR || null);
    }

    async setPlan(plan: SignoPlan) {
        sessionStorage.setItem(SIGNO_EMPRESA, JSON.stringify(plan));
    }

    async getPlan(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(SIGNO_PLAN) || null);
    }
    
    async setEmpresa(empresa: SignoEmpresa) {
        sessionStorage.setItem(SIGNO_EMPRESA, JSON.stringify(empresa));
    }

    async getEmpresa(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(SIGNO_EMPRESA) || null);
    }

    async setSede(sede: SignoSede) {
        sessionStorage.setItem(SIGNO_SEDE, JSON.stringify(sede));
    }

    async getSede(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(SIGNO_SEDE) || null);
    }

    async setDoctor(doctor: SignoDoctor) {
        sessionStorage.setItem(SIGNO_DOCTOR, JSON.stringify(doctor));
    }

    async getDoctor(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(SIGNO_DOCTOR) || null);
    }

    async setLocalidad(localidad: SignoLocalidad) {
        sessionStorage.setItem(
            SIGNO_LOCALIDAD,
            JSON.stringify(localidad)
        );
    }

    async getLocalidad(): Promise<string> {
        return sessionStorage.getItem(
            JSON.parse(SIGNO_LOCALIDAD) || null
        );
    }

    async setCentroMedico(centroMedico: SignoCentroMedico) {
        sessionStorage.setItem(
            SIGNO_CENTRO_MEDICO,
            JSON.stringify(centroMedico)
        );
    }

    async getCentroMedico(): Promise<string> {
        return sessionStorage.getItem(
            JSON.parse(SIGNO_CENTRO_MEDICO) || null
        );
    }

    async setUsuarioEntreUsuario(usuarioEntreUsuario: UserEntreUser) {
        sessionStorage.setItem(USUARIO_ENTRE_USUARIO, JSON.stringify(usuarioEntreUsuario));
    }

    async getUsuarioEntreUsuario(): Promise<string> {
        return sessionStorage.getItem(JSON.parse(USUARIO_ENTRE_USUARIO) || null);
    }

     // Limpiar ambos: sessionStorage y localStorage
    clearAllStorage() {
        sessionStorage.clear();
        localStorage.clear();
    }

    // Guarda el email en el almacenamiento local
    setEmail(email: string): void {
        localStorage.setItem(EMAIL_KEY, email);
        sessionStorage.setItem(
            EMAIL_KEY,
            JSON.stringify(email)
        );
    }

    // Obtiene el email guardado del almacenamiento local
    async getEmail(): Promise<string> {
        return sessionStorage.getItem(
            JSON.parse(EMAIL_KEY) || null
        );
    }


}
